<template>
  <div class="centerM w1200">
    <div class="content_left">
      <div class="l_title">关于项目</div>
      <div v-for="item in deslist" :key="item.title" class="l_content">
        <p class="x_title">{{ item.title }}</p>
        <span class="x_des">{{ item.des }}</span>
      </div>
    </div>
    <div class="content_right">
      <img
        :src="require('@/assets/images/province/about.png')"
        alt="项目示例图"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      deslist: [
        {
          title: "项目背景",
          des: "按照《河南省财政厅关于印发河南省省级政府公物仓日常管理规程的通知中》（豫财资〔2011〕5号）文件要求，资产管理中心负责建立公物仓资产信息平台，及时提供公物仓资产的相关信息，对公物仓资产数据信息及时更新，并做到信息资源共享；按省财政厅资产管理处要求，将政府公物仓资产管理纳入行政事业单位资产管理系统，推进省级政府公物仓信息化建设。",
        },
        {
          title: "项目介绍",
          des: "河南省政府公物仓管理平台涉及的业务范围如下：国有资产的借入、借出、调入、调出；仓储中心管理；国有行政事业单位的资产借入，归还，调入，调出；省级罚没财物移交办理，评估，拍卖，收入上缴；地方罚没财物移交办理，评估，拍卖，收入上缴；拍卖信息统一发布，文件归档等业务。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.centerM {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  .content_left {
    width: 50%;
    color: #333333;
    font-family: PingFangSC-Semibold, PingFang SC;
    .l_title {
      border-left: 4px solid #388cff;
      padding-left: 4px;
      font-weight: 500;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .l_content {
      width: 80%;
      .x_title {
        font-size: 22px;
        font-weight: 600;
      }
      .x_des {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  .content_right {
    width: 50%;
    height: 524px;
    padding: 80px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(56, 140, 255, 0.4) 100%
    );
    box-shadow: 0px 0px 20px 0px #d6dde7;
    border-radius:  100px 0px 0px 100px;
    backdrop-filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 104%;
    }
  }
}
</style>