<template>
  <div class="definition_box w1200">
    <div class="definition_title">风格定义</div>
    <div class="definition_pro">
      经过前期用户研究和产品定位，明确视觉风格方向。根据高效、简洁、数据安全建立视觉风格，贴合产品调性的情况下也考虑目标用户的情况下。在保持界面美观的基础上，首要目的是简单高效的原则，清晰友好的交互，帮助用户提升工作效率。
    </div>
    <div class="content_box">
      <div class="content_left">
        <img
          style="height: 200px; margin-top: 10px"
          :src="require('@/assets/images/province/right.png')"
          alt=""
        />
        <div class="pro_box">
          <div class="title">高效</div>
          <div class="content" style="margin: 10px 0 70px 0">
            简化流程，规范化与组件化产品，效率为主，操作便捷。
          </div>
          <div class="title">简约</div>
          <div class="content" style="margin: 10px 0 70px 0">
            简约为主，模块化设计，信息层次关系清晰，减少视觉噪点。
          </div>
          <div class="title">安全</div>
          <div class="content" style="margin: 10px 0 70px 0">
            文件实现电子化周转，高效安全，文件管理人员只需将最终审批文件打印归档即可
          </div>
        </div>
      </div>
      <div class="content_right">
        <img
          style="width: 100%; height: 420px; margin-top: 100px"
          :src="require('@/assets/images/province/style.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.content {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.definition_box {
  padding-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
//   background: rgba(70, 160, 252, 0.06);
  .definition_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .definition_pro {
    font-size: 18px;
  }
  .content_box {
    display: flex;
    justify-content: space-between;
    .content_left {
      width: 45%;
      .pro_box {
        background: url("../../../../assets/images/province/gong.png") no-repeat
          bottom;
      }
    }
    .content_right {
      width: 50%;
    }
  }
}
</style>
