import Router from 'vue-router'
import home from '@/views/home'
import cases from '@/views/case'
import background from '@/views/background'
import customize from '@/views/background/customize.vue'
import applets from '@/views/applets'
import trait from '@/views/applets/trait.vue'
import blockchain from '@/views/blockchain'
import CZTStandardDetail from '@/views/case/caseDetail/CZTStandardDetail'
import DJHEDetail from '@/views/case/caseDetail/DJHEDetail'
import meteorological from '@/views/case/meteorological/meteorological'
import LabourUnion from '@/views/case/LabourUnion/LabourUnion'
import equipment from '@/views/case/equipment/equipment'
import provinceManageDetil from '@/views/case/provinceManageDetil/provinceManageDetil'
import orderCar from '@/views/case/orderCar/orderCar'
import wisdom from '@/views/case/wisdom/wisdom'
import weather from '@/views/case/weather/weather'
import orderCarApp from '@/views/case/orderCarApp/orderCarApp'
import cardHorizon from '@/views/case/cardHorizon/cardHorizon'
// customize
import app from '@/views/app'
export default new Router({
    routes: [
        {
            path : '/',  
            name : 'home',
            component :  home
        },{
            path : '/case',  
            name : 'case',
            component :  cases
        },{
            path : '/background',  
            name : 'background',
            component :  background
        },{
            path : '/customize',  
            name : 'customize',
            component :  customize
        },{
            path : '/applets',  
            name : 'applets',
            component :  applets
        },{
            path : '/trait',  
            name : 'trait',
            component :  trait
        },{
            path : '/blockchain',  
            name : 'blockchain',
            component :  blockchain
        },{
            path : '/app',  
            name : 'app',
            component :  app
        },{
            path : '/CZTStandardDetail',
            name : 'CZTStandardDetail',
            component :  CZTStandardDetail
        },
        {
            path: '/DJHEDetail',
            name : 'DJHEDetail',
            component :  DJHEDetail
        },
        {
            path : '/meteorological',
            name : 'meteorological',
            component :  meteorological
        },
        {
            path : '/LabourUnion',
            name : 'LabourUnion',
            component :  LabourUnion
        },
        {
            path : '/equipment',
            name : 'equipment',
            component :  equipment
        },
        {
            path : '/provinceManageDetil',
            name : 'provinceManageDetil',
            component :  provinceManageDetil
        },
        {
            path : '/orderCar',
            name : 'orderCar',
            component :  orderCar
        },
        {
            path : '/wisdom',
            name : 'wisdom',
            component :  wisdom
        },
        {
            path : '/weather',
            name : 'weather',
            component :  weather
        },
        {
            path : '/orderCarApp',
            name : 'orderCarApp',
            component :  orderCarApp
        },
        {
            path : '/cardHorizon',
            name : 'cardHorizon',
            component :  cardHorizon
        }
    ],
    mode: "hash",

})