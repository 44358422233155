<template>
  <header class="w_header" :style="{color:colorStyle}">
    <div class="logo">
      <img src="@/assets/images/icon/logo.png" />
      <div class="logo_size">泽尚网络</div>
    </div>
    <div class="menu">
      <div
        v-for="(item ,index) in menusList"
        :key="item.name"
        @click="goTo(item.link)"
        :class="{ 'menu_active': menuActive === index, 'color_black': menuActive == 0 || menuActive == 1 }"
        class="menu_item"
      >
        <div >{{ item.name }}</div>
        <div class="border"></div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    menuActive: {
      type: Number
    },
    colorStyle: {
      type: String
    }
  },
  data() {
    return {
      menusList: [
        { name: "首页", link: "/" },
        { name: "产品中心", link: "/case" },
        { name: "后台开发", link: "/background" },
        { name: "小程序开发", link: "/applets" },
        { name: "区块链开发", link: "/blockchain" },
        { name: "移动App开发", link: "/app" }
      ]
    };
  },
  created() {
    console.log(this.colorStyle);
  },
  methods: {
    goTo(link) {
      this.$router.push({
        path: link
      });
    }
  }
};
</script>

<style lang="less">
.w_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    display: flex;
    margin-left: 200px;
    .logo_size {
      font-size: 30px;
      font-family: COCA-COLACAREFONT-TEXTBOLD--GB1-0,
        COCA-COLACAREFONT-TEXTBOLD--GB1;
      font-weight: normal;
      margin-left: 21px;
      color: #F5F5F6;
    }
  }

  .menu {
    display: flex;
    padding-right: 200px;
    .menu_item {
      height: 45px;
      line-height: 45px;
      // color: #fff;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      color: #F5F5F6;
      font-weight: 500;

      .border {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 80px;
        height: 4px;
        margin-left: -40px;
        border-radius: 3px;
      }
    }
    .menu_item:hover,
    .menu_active {
      .border {
        background: #ffffff;
      }
    }
    .menu_item:not(:first-child) {
      margin-left: 40px;
    }
    .color_black.menu_active, .color_black:hover {
      .border {
        background: hsla(228, 54%, 31%, 1) !important;
      }
    }
    .color_black {
      color: hsla(228, 54%, 31%, 1) !important;
    }
  }
}
</style>
