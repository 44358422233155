<template>
  <div class="w1200">
    <div class="vision_box">
      <div class="vision_left">
        <div>
          <span class="yuan"></span>
          <span class="yuan_title">视觉规范</span>
        </div>
        <div class="calendar">
          <div class="calendar_left">
            <img
              style="width: 100%; margin-top: 218px"
              :src="require('@/assets/images/weather/date.png')"
              alt=""
            />
            <div class="title" style="margin-top: 10px">污染日历</div>
            <div class="content">
              AQI对应颜色在日历上直观展示当天空气质量，可通过点击某一天查看当天详情，包括六项污染物的监测值，超标因子警示展示，污染天数直观统计当月不同污染级别天数
            </div>
            <div class="title" style="margin-top: 430px">实时排名</div>
            <div class="content">
              名采用列表，确保信息易于抓取。用户可一目了然各市空气质量，可进行日累计排名，小时排名，日排名，周排名，月排名，自定义排名进行实时检测。
            </div>
          </div>
          <div class="calendar_right">
            <div class="calendar_title">污染日历</div>
            <img
              style="width: 100%; margin-top: 20px"
              :src="require('@/assets/images/weather/calendar.png')"
              alt=""
            />
            <div class="calendar_title" style="margin-top: 210px">污染日历</div>
            <img
              style="width: 100%; margin-top: 20px"
              :src="require('@/assets/images/weather/ranking.png')"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="vision_right">
        <div class="air_left">
          <div class="air_title" style="margin-top: 48px">首页</div>
          <img
            style="width: 100%; margin-top: 20px"
            :src="require('@/assets/images/weather/air.png')"
            alt=""
          />
          <div class="air_title" style="margin-top: 207px">地图展示</div>
          <img
            style="width: 100%; margin-top: 20px"
            :src="require('@/assets/images/weather/map.png')"
            alt=""
          />
        </div>
        <div class="air_right">
          <img
            style="width: 100%; margin-top: 139px"
            :src="require('@/assets/images/weather/aqi.png')"
            alt=""
          />
          <div class="title" style="margin-top: 20px">AQI</div>
          <div class="content">
            主要展示六项污染物的污染指数，用户通过此页面可了解到检测值范围对应的污染指数，污染级别有所对应的颜色区分
          </div>
          <div class="title" style="margin-top: 485px">地图展示</div>
          <div class="content">
            通过GIS直观展示市区空气质量检测点位，点击气泡后显示相应站点排名详细信息
          </div>
        </div>
      </div>
    </div>
    <div class="icon_box">
      <div class="icon_left">
        <div>
          <span class="yuan"></span>
          <span class="yuan_title">视觉规范</span>
        </div>
        <div class="left_box">
          <div class="left_title">
            <span class="tian">天气</span><span class="icon">图标</span>
          </div>
          <img
            style="width: 100%; margin-top: 56px"
            :src="require('@/assets/images/weather/airIcon.png')"
            alt=""
          />
        </div>
      </div>
      <div class="icon_right">
        <div
          class="left_box"
          style="margin-top: 76px; padding: 14px 35px 32px 35px"
        >
          <div class="size_title">常用字体</div>
          <div class="size_top">
            <div style="font-size: 10px">
              <p>苹方-简</p>
              <p>10pt</p>
            </div>
            <div style="font-size: 12px; margin-top: -7px" class="16pt mar20">
              <p>苹方-简</p>
              <p>12pt</p>
            </div>
            <div style="font-size: 14px; margin-top: -16px" class="18pt mar20">
              <p>苹方-简</p>
              <p>14pt</p>
            </div>
            <div style="font-size: 16px; margin-top: -24px" class="20pt mar20">
              <p>苹方-简</p>
              <p>16pt</p>
            </div>
          </div>
          <div>
            <div class="size_title" style="margin-top: 10px">常用颜色</div>
            <ul>
              <li v-for="item in colorList" :key="item.id">
                <div
                  class="color_box"
                  :style="{ background: item.color }"
                ></div>
                <div style="width: 100%; display: flex">
                  <span style="margin: 0px 20px 0px 16px">{{
                    item.color
                  }}</span>
                  <span>{{ item.content }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="exhibition">
      <div>
        <span class="yuan"></span>
        <span class="yuan_title">页面展示</span>
      </div>
      <img
        style="width: 100%; margin-top: 20px"
        :src="require('@/assets/images/weather/about.png')"
        alt=""
      />
    </div>
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      colorList: [
        {
          id: 1,
          color: "#45C79B",
          content: "主色调，用于按钮，图标，提示文字以及选中状态颜色",
        },
        {
          id: 2,
          color: "#FF9939",
          content: "用于页面按钮，提示字颜色",
        },
        {
          id: 3,
          color: "#AAE9FF",
          content: "用于图标",
        },
        {
          id: 4,
          color: "#333333",
          content: "用于标题，内容",
        },
        {
          id: 5,
          color: "#999999",
          content: "用于内容、简介等",
        },
      ],
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #45c79b;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.content {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}
.left_box {
  width: 100%;
  height: 464px;
  background: #ffffff;
  padding: 43px 66px 32px 66px;
  margin-top: 40px;
  box-shadow: 0px 0px 20px 0px #d6e7e0;
}
.mar20 {
  margin-left: 40px;
}

.vision_box {
  display: flex;
  justify-content: space-between;
  font-family: PingFangSC-Medium, PingFang SC;
  margin-top: 100px;
  color: #343449;
  .vision_left {
    width: 49%;
    padding: 50px 0 113px 0;
    background: rgba(69, 199, 155, 0.05);
    .calendar {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      .calendar_left {
        width: 48%;
      }
      .calendar_right {
        width: 48%;
        .calendar_title {
          display: flex;
          justify-content: flex-end;
          font-size: 20px;
          font-weight: 500;
          color: #00734c;
          margin-top: 125px;
        }
      }
    }
  }
  .vision_right {
    width: 49%;
    display: flex;
    justify-content: space-between;
    padding: 48px 7px 192px 20px;
    background: rgba(69, 199, 155, 0.05);
    .air_left {
      width: 48%;
      .air_title {
        font-size: 20px;
        font-weight: 500;
        color: #00734c;
      }
    }
    .air_right {
      width: 48%;
    }
  }
}
.icon_box {
  display: flex;
  justify-content: space-between;
  font-family: PingFangSC-Medium, PingFang SC;
  background: rgba(69, 199, 155, 0.05);
  margin-top: 100px;
  .icon_left {
    width: 46%;
    .left_box {
      .left_title {
        display: flex;
        justify-content: center;
        font-size: 72px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        letter-spacing: 1px;
        .tian {
          color: #ffffff;
          -webkit-text-stroke: 1px #45c79b;
          text-stroke: 1px #45c79b;
        }
        .icon {
          color: #45c79b;
        }
      }
    }
  }
  .icon_right {
    width: 46%;
    .left_box {
      .size_title {
        border-left: 2px solid #5dc42e;
        font-size: 20px;
        font-weight: 500;
        color: #343449;
        line-height: 20px;
        padding-left: 5px;
      }
      .size_top {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
      }
      .color_box {
        width: 60px;
        height: 24px;
        border-radius: 4px;
      }
    }
  }
}
.exhibition {
  margin-top: 100px;
}

.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
