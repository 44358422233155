<template>
  <div class="w1200" style="margin-top: 100px">
    <div class="exhibition">
      <div class="exhibition_left">
        <div class="ball" style>
          <span class="ballNumber">2</span>
          <span class="ballTranslate">Exhibition</span>
        </div>
        <div class="contentTitle" style="font-size: 32px">项目展示</div>
        <div style="margin-top: 54px">
          <span class="yuan"></span>
          <span class="yuanTitle">首页概述</span>
        </div>
        <img
          style="margin-top: 94px; width: 100%"
          :src="require('@/assets/images/cardHorizon/search.png')"
          alt
        />
        <div class="contentTitle" style="margin-top: 20px">搜索功能</div>
        <div class="content" style="margin-top: 10px">
          增加搜索框，提高用户体验。加入搜索功能，用户的搜索需求会出现在两个时间点，
          <span
            style="color: #f74f53"
          >一是搜索目的明确，二是在使用APP的过程中产生搜索需求。</span>
        </div>
        <div style="box-shadow: 0px 0px 20px 0px #d6dde7; margin-top: 106px">
          <img style="width: 100%" :src="require('@/assets/images/cardHorizon/shaixuan.png')" alt />
        </div>
        <div class="contentTitle" style="margin-top: 20px">筛选功能</div>
        <div class="content" style="margin-top: 10px">
          筛选比较直观，且用户选择完后可以及时看到下面筛选出来的结果集合了
          <span style="color: #f74f53">滑动切换手势的舒适和点击选择的精准快速</span>
          ，缓慢浏览/快速查找的需求都能被满足。
        </div>
      </div>
      <div class="exhibition_rig">
        <img style="margin-top: 120px" :src="require('@/assets/images/cardHorizon/home.png')" alt />
        <div class="unscramble">
          <div class="content" style="margin-top: 195px">
            用户通过消息的提醒，
            <span style="color: #f74f53">第一时间</span>得到信息通知
          </div>
          <div class="ranking">
            <img style :src="require('@/assets/images/cardHorizon/rank.png')" alt />
          </div>
          <div class="contentTitle" style="margin-top: 12px">金刚区功能</div>
          <div class="content" style="margin-top: 10px">
            将瓷片区构成组件化，提高工作效率。简捷的布局
            <span style="color: #f74f53">实现高效率流量分发。</span>
          </div>
          <div class="contentTitle" style="margin-top: 146px">瓷片区功能</div>
          <div class="content" style="margin-top: 10px">
            金刚区帮助用户快速找到所需功能入口，
            <span style="color: #f74f53">衬托功能点</span>，提高点击率。
          </div>
        </div>
        <img class="noticeLine" :src="require('@/assets/images/cardHorizon/noticeLine.png')" alt />
        <img class="exbLine" style :src="require('@/assets/images/cardHorizon/exbLine.png')" alt />
      </div>
    </div>
    <div class="broadcast">
      <div class="broadcast_left">
        <div style="width: 35%">
          <div style>
            <span class="yuan"></span>
            <span class="yuanTitle">直播详情</span>
          </div>
          <img style="margin-top: 26px" :src="require('@/assets/images/cardHorizon/place.png')" alt />
          <div class="contentTitle" style="margin-top: 50px">风格设定</div>
          <div class="content" style="margin-top: 10px">
            在设计上，使用非常流行的卡片化设计+圆角设计，这样设计能让
            <span style="color: #f74f53">界面具有亲和力，更有活力感，信息更聚集</span>，并且采用去现况的设计语言。利用率留白区分板块与板块之间的距离，这样的设计让界面更轻量化。
          </div>
          <div class="contentTitle" style="margin-top: 20px">瓷片区功能</div>
          <div class="content" style="margin-top: 10px">
            直播以瀑布流的形式展现，有利于用户
            <span style="color: #f74f53">沉浸式浏览</span>，增加平台的使用时长和用户留存率。
          </div>
        </div>
        <div style="width: 65%">
          <img
            style="width: 100%; margin-top: 80px"
            :src="require('@/assets/images/cardHorizon/style.png')"
            alt
          />
        </div>
        <img class="broadLine" :src="require('@/assets/images/cardHorizon/broadLine.png')" alt />
      </div>
      <div class="broadcast_right">
        <div style="width: 60%">
          <img
            style="width: 100%; height: 100%"
            :src="require('@/assets/images/cardHorizon/xinghao.png')"
            alt
          />
        </div>
        <div style="width: 40%">
          <div class="contentTitle" style="margin-top: 140px">评论功能</div>
          <div class="content" style="margin-top: 10px">
            直播是平台主要社交功能来源之一，用户可以在这里浏览行业资讯和最新车型的话题，点击瓷片区可进入直播详情页，可以
            <span
              style="color: #f74f53"
            >发表评论参与互动。</span>
            <img
              style="margin-top: 82px"
              :src="require('@/assets/images/cardHorizon/comment.png')"
              alt
            />
          </div>
        </div>
      </div>
    </div>
    <div class="energy">
      <div class="pink"></div>
      <div class="energy_box">
        <div class="energy_left">
          <div style>
            <span class="yuan"></span>
            <span class="yuanTitle">新能源</span>
          </div>
          <div class="contentTitle" style="margin-top: 56px">筛选功能</div>
          <div class="content" style="margin-top: 10px">在交互流程上：点击筛选框，采用了弹层，沉浸式完成选择，提高用户留存。</div>
          <img style="margin-top: 40px" :src="require('@/assets/images/cardHorizon/model.png')" alt />
        </div>
        <div class="energy_right">
          <img style="width: 50%" :src="require('@/assets/images/cardHorizon/cardDetil.png')" alt />
          <div style="width: 50%; margin-left: 100px">
            <div class="contentTitle" style="margin-top: 220px">卡片功能</div>
            <div class="content" style="margin-top: 10px">
              卡片中信息层级清晰有层级，让用户一览无余。
              <span style="color: #f74f53">价格和销量融入了品牌色，增强品牌认知</span>，提高用户点击率。
            </div>
          </div>
          <img class="energyLine" :src="require('@/assets/images/cardHorizon/energyLine.png')" alt />
        </div>
      </div>
    </div>
    <div class="personal">
      <div class="personal_left">
        <div style>
          <span class="yuan"></span>
          <span class="yuanTitle">个人中心</span>
        </div>
        <div class="contentTitle" style="margin-top: 94px">核心模块</div>
        <div class="content" style="margin-top: 10px">
          个人中心的核心模块就是功能入口，有层级的布局尤为重要，在这里采用了
          <span style="color: #f74f53">宫格式+列表式</span>的组合方式进行对比，能够很好地突出宫格里的内容，列表式层次展示清晰，灵活性高方便信息后期扩展；宫格式其优势可言强化功能操作，页面视觉效果更好。
        </div>
        <div class="lattice_box">
          <div>宫格式</div>
          <div class="lattice">
            <img style :src="require('@/assets/images/cardHorizon/gongge.png')" alt />
          </div>
        </div>
        <div class="lattice_box">
          <div>列表式</div>
          <img style="margin-left: 40px" :src="require('@/assets/images/cardHorizon/list.png')" alt />
        </div>
      </div>
      <div class="personal_right">
        <img style="width: 50%" :src="require('@/assets/images/cardHorizon/main.png')" alt />
        <div style="width: 50%; margin-left: 100px">
          <div class="contentTitle" style="margin-top: 240px">功能入口</div>
          <div class="content" style="margin-top: 10px">
            采用差异化图标、分块以及颜色来敲掉核心功能入口，这样会让
            <span style="color: #f74f53">核心功能入口脱颖而出</span>，方便用户使用。
          </div>
          <div class="contentTitle" style="margin-top: 100px">广告</div>
          <div class="content" style="margin-top: 10px">
            不同页面都有广告推广，就是把
            <span style="color: #f74f53">产品的知名度提高</span>吸引一定的客户，
          </div>
        </div>
        <img class="epersonalLine" :src="require('@/assets/images/cardHorizon/energyLine.png')" alt />
        <img class="infoLine" :src="require('@/assets/images/cardHorizon/infoLine.png')" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {}
};
</script>
<style lang="less" scoped>
.ballNumber {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background: #f74f53;
}
.ballTranslate {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(247, 79, 83, 0.1);
}
.contentTitle {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.yuan {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #f74f53;
  vertical-align: text-top;
}
.yuanTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #343449;
  margin-left: 4px;
}
.contentTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.content {
  font-size: 18px;
  font-weight: 400;
}

.exhibition {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .exhibition_left {
    width: 30%;
  }
  .exhibition_rig {
    width: 68%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .unscramble {
      padding-left: 40px;
      .ranking {
        width: 317px;
        height: 103px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px #d6dde7;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 88px;
      }
    }
    .noticeLine {
      position: absolute;
      top: 204px;
      left: 343px;
      width: 125px;
    }
    .exbLine {
      position: absolute;
      bottom: 228px;
      left: 335px;
      width: 194px;
    }
  }
}

.broadcast {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  .broadcast_left {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .broadLine {
      position: absolute;
      bottom: 246px;
      left: 104px;
      width: 167px;
    }
  }
  .broadcast_right {
    width: 50%;
    background: url("../../../../assets/images/cardHorizon/rankBg.png")
      no-repeat;
    background-size: 456px 499px;
    background-position: left bottom;
    display: flex;
    justify-content: space-between;
  }
}

.energy {
  position: relative;
  .pink {
    position: absolute;
    bottom: 0;
    width: 1518px;
    height: 325px;
    background: rgba(246, 78, 82, 0.05);
    z-index: -999;
  }

  .energy_box {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    .energy_left {
      width: 25%;
    }
    .energy_right {
      width: 65%;
      display: flex;
      justify-content: space-between;
      position: relative;
      .energyLine {
        position: absolute;
        top: 230px;
        left: 265px;
      }
    }
  }
}

.personal {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  .personal_left {
    width: 25%;
    .lattice_box {
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;
      .lattice {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 40px;
        width: 224px;
        height: 63px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px #d6dde7;
        border-radius: 10px;
      }
    }
  }
  .personal_right {
    width: 65%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .epersonalLine {
      position: absolute;
      top: 251px;
      left: 308px;
      width: 179px;
    }
    .infoLine {
      position: absolute;
      bottom: 151px;
      left: 330px;
      width: 156px;
    }
  }
}
</style>
