<template>
  <div>
    <div class="product w1200">
      <div class="left">
        <div class="title" style="margin-top: 10px">订车宝APP</div>
        <div class="content">
          订车宝打造为中国广大汽车消费者提供买车、用车、养<br />车及与汽车生活相关的全程服务，以全面、专业、<br />
          可信赖、高互动性的内容，多层次、多维度地影响最广<br />泛的汽车消费者，是中国最具价值的互联网汽车品<br />
          牌传播和营销平台
        </div>
        <div class="left_box">
          <img
            style="width: 70%; margin-top: 28px"
            :src="require('@/assets/images/orderApp/left01.png')"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <img
          style="width: 100%"
          :src="require('@/assets/images/orderApp/right.png')"
          alt=""
        />
      </div>
    </div>
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.product {
  display: flex;
  justify-content: space-between;
  //   background: rgba(70,160,252,0.06);
  .left {
    width: 48%;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #333333;
    padding-top: 178px;
    .title {
      font-size: 36px;
      font-weight: 500;
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
    }
    .left_box {
      display: flex;
      justify-content: flex-end;
      background: url("../../../../assets/images/orderCar/left.png") no-repeat;
      background-size: 370px 210px;
      background-position: left bottom;
    }
  }
  .right {
    width: 48%;
    padding-top: 29px;
  }
}
.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
