<template>
  <div class="exhibition_box w1200">
    <div class="exhibition_title">项目展示</div>
    <div>
      <span class="yuan"></span>
      <span class="yuan_title">登录页面</span>
    </div>
    <div class="login_box contentBox">
      <div class="login_left">
        <div>
          旧：登录页使用科技集合图片修饰，颜色采用系统主色调，背景过于<br />简约
        </div>
        <img
          style="width: 100%; margin-top: 20px"
          :src="require('@/assets/images/equipment/oldlogin.png')"
          alt=""
        />
        <div class="login_pro">旧登录页</div>
      </div>
      <div class="login_right">
        <div>
          新：登录页使用扁平插画，插画内容为数据统计类部分，然后员工利益数据统计工作，突出登录主体，实用性和美观较强。
        </div>
        <img
          style="width: 100%; margin-top: 20px"
          :src="require('@/assets/images/equipment/newlogin.png')"
          alt=""
        />
        <div class="login_pro">新登录页</div>
      </div>
    </div>
    <div style="margin-top: 80px">
      <span class="yuan"></span>
      <span class="yuan_title">自主健康页面</span>
    </div>
    <div class="autonomy_box contentBox">
      <div class="autonomy_left">
        <div>
          登录页使用扁平插画，插画内容为数据统计类部分，然后员工利益数据统计工作，相辅相成颜色采用系统主色调，背景简约干净，突出登录主体，实用性较强。
        </div>
        <img
          style="width: 100%; margin-top: 50px"
          :src="require('@/assets/images/equipment/autonomy.png')"
          alt=""
        />
        <img
          class="autonom_line"
          :src="require('@/assets/images/equipment/line01.png')"
          alt=""
        />
      </div>
      <div class="autonomy_right">
        <div class="autonomy_top">
          <div
            class="autonomy_product"
            v-for="item in productData"
            :key="item.id"
          >
            <div class="autonomy_title">
              <p>{{ item.title }}</p>
              <p>
                <span
                  class="number"
                  :style="[{ color: `${item.colorStyle}` }]"
                  >{{ item.num }}</span
                >件
              </p>
            </div>
            <img :src="item.path" alt="" />
          </div>
        </div>
        <div class="autonomy_bottom">
          <p class="title" style="margin-top: 25px">状态评估</p>
          <p class="content">
            使用卡片形式展示当前系统的百分比及系统装备即将到寿、短缺备件、标检需求、性能风险的状态结果。
          </p>
          <p class="title" style="margin-top: 20px">现存故障</p>
          <p class="content">
            展示装备的故障件数饼状图，及分系统和子系统的故障记录、维护维修记录柱状图
          </p>
        </div>
      </div>
    </div>
    <div style="margin-top: 80px">
      <span class="yuan"></span>
      <span class="yuan_title">申请管理</span>
    </div>
    <div class="applyManage_box contentBox">
      <div class="applyManage_left">
        <img
          style="width: 100%; height: 100%"
          :src="require('@/assets/images/equipment/apply.png')"
          alt=""
        />
        <img
          class="applyLine"
          :src="require('@/assets/images/equipment/applyLine.png')"
          alt=""
        />
      </div>
      <div class="applyManage_right">
        <p class="title">对数据进行分类，提高数据查看效率</p>
        <p class="content">
          表格属于频繁出现的业务模块，是实用多维度数据进行统一的结构展示，让用户清晰的看到在同一主题下的多条数据对比，同时保证数据的完整性。
        </p>
        <img
          style="width: 100%"
          :src="require('@/assets/images/equipment/applyTab.png')"
          alt=""
        />
        <p class="title">功能按钮分布</p>
        <p class="content">
          按钮模块突出，提高工作效率，减少了流程的冗杂，节省时间。
        </p>
        <p class="title" style="margin-top: 55px">按数量分组</p>
        <p class="content">
          分页器作为局部导航组件，可以在数据较大的业务环境下使用
        </p>
      </div>
    </div>
    <div style="margin-top: 80px">
      <span class="yuan"></span>
      <span class="yuan_title">缺省页</span>
    </div>
    <div class="default_box contentBox">
      <div class="default_left">
        <img
          style="width: 100%"
          :src="require('@/assets/images/equipment/default.png')"
          alt=""
        />
        <img
          class="defaultLine"
          :src="require('@/assets/images/equipment/defaultLine.png')"
          alt=""
        />
      </div>
      <div class="default_right">
        <div class="content" style="margin-top: 160px">
          当没有数据的情况下，页面出现提醒：抱歉，该页面没有任何数据，提高页面美观度。
        </div>
      </div>
    </div>
    <div style="margin-top: 80px">
      <span class="yuan"></span>
      <span class="yuan_title">页面展示</span>
    </div>
    <div>
      <img
        style="width: 100%; margin-top: 20px"
        :src="require('@/assets/images/equipment/exhibition.png')"
        alt=""
      />
    </div>
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      productData: [
        {
          id: 1,
          title: "即将到寿",
          num: 4,
          path: require("@/assets/images/equipment/autonomy01.png"),
          colorStyle: "#859CED",
        },
        {
          id: 2,
          title: "短缺备件",
          num: 4,
          path: require("@/assets/images/equipment/autonomy02.png"),
          colorStyle: "#FF5B5B",
        },
        {
          id: 3,
          title: "标检需求",
          num: 4,
          path: require("@/assets/images/equipment/autonomy03.png"),
          colorStyle: "#32EBAB",
        },
        {
          id: 4,
          title: "性能风险",
          num: 4,
          path: require("@/assets/images/equipment/autonomy04.png"),
          colorStyle: "#F6D322",
        },
      ],
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #46a0fc;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}
.login_pro {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 22px;
  color: #999999;
}
.contentBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.content {
  font-size: 18px;
  margin-top: 10px;
}

.exhibition_box {
  margin-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .exhibition_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .login_box {
    .login_left {
      width: 45%;
    }
    .login_right {
      width: 45%;
    }
  }
}
.autonomy_box {
  .autonomy_left {
    width: 45%;
    position: relative;
    .autonom_line {
      position: absolute;
      top: 263px;
      left: 331px;
      width: 324px;
      height: 118px;
    }
  }
  .autonomy_right {
    width: 45%;
    .autonomy_top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .autonomy_product {
        width: 45%;
        height: 100px;
        padding: 25px 20px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px #d6dde7;
        .autonomy_title {
          .number {
            font-size: 24px;
            margin-right: 10px;
          }
        }
        img {
          width: 50px;
          height: 50px;
          // margin-top: 20px;
        }
      }
    }
    .autonomy_bottom {
      color: #333333;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
.applyManage_box {
  .applyManage_left {
    width: 45%;
    position: relative;
    .applyLine {
      position: absolute;
      top: 300px;
      left: 384px;
      width: 268px;
    }
  }
  .applyManage_right {
    width: 45%;
  }
}
.default_box {
  .default_left {
    width: 45%;
    position: relative;
    .defaultLine {
      position: absolute;
      top: 166px;
      left: 353px;
      width: 296px;
    }
  }
  .default_right {
    width: 45%;
  }
}
.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
