<template>
  <div class="exhibition_box w1200">
    <div class="exhibition_title">项目展示</div>
    <div class="add_box">
      <div class="add_left">
        <div style="">
          <span class="yuan"></span>
          <span class="yuan_title">添加申请函</span>
        </div>
        <div class="content" style="margin: 20px 0">
          添加申请函，添加的清单数量与申请函的件数需保持一致且各个文件只允许上传PDF文件，填写完信息后将移交到下一个审批人进行审核管理。可以满足业务人员、管理人员、决策人员所面临的的不同需求。
        </div>
        <img
          style="width: 100%;
"
          :src="require('@/assets/images/province/addLeft.png')"
          alt=""
        />
      </div>
      <div class="add_right">
        <img
          style="width: 100%;box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/addRight.png')"
          alt=""
        />
        <div>
          <div class="title">上传文件</div>
          <div class="content" style="margin-top: 10px">
            可点击选择上传文件，也可以拖拽上传文件，删除文件可以一键清空，也可以删除单个文件，提升用户体验的同时也增强页面美观度
          </div>
        </div>
      </div>
    </div>
    <div class="add_box" style="margin-top: 100px">
      <div class="add_left">
        <div style="">
          <span class="yuan"></span>
          <span class="yuan_title">罚没流程概括</span>
        </div>
        <div class="content" style="margin: 20px 0">
          首页的概括总结了负责对各个公检法单位涉案财物和各个单位上交的有价证券、礼品等工作进行集中管理、有效的统计了资产接收的数量、上缴的金额等，提高了工作效率，减少了流程的冗杂，节省了时间。
        </div>
        <img
          style="width: 100%"
          :src="require('@/assets/images/province/fines.png')"
          alt=""
        />
      </div>
      <div class="add_right">
        <img
          style="width: 100% ;  box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/finesBing.png')"
          alt=""
        />
        <div class="title">新增</div>
        <div class="content" style="margin-top: 10px">
          将新增的资产陈列在表格里方便统计、查看、对信息实现资源整合。
        </div>
        <img
          style="width: 100%; margin-top: 60px   ;box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/finesZhu.png')"
          alt=""
        />
        <div class="title">数据统计</div>
        <div class="content" style="margin-top: 10px">
          柱状图可以便于用户理解大量数据以及数据相互之间的关系。另一方面就是让用户通过视觉化的符号，更加快速直观的读取原始数据。
        </div>
      </div>
    </div>
    <div class="add_box" style="margin-top: 100px">
      <div class="add_left">
        <div style="">
          <span class="yuan"></span>
          <span class="yuan_title">流程管理</span>
        </div>
        <div class="content" style="margin: 20px 0">
          通过查看全部案件类型来区分案件类别，可以查看全部审核状态来区分待审核的申请函
        </div>
        <img
          style="width: 100%  ; box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/processList.png')"
          alt=""
        />
      </div>
      <div class="add_right">
        <img
          style="box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1);margin-right: 100px"
          :src="require('@/assets/images/province/select.png')"
          alt=""
        />
        <img
          style="margin-bottom: 33px;box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/selectList.png')"
          alt=""
        />
        <div class="title">气泡窗口</div>
        <div class="content" style="margin-top: 10px">
          支持根据省级交办和属地办理两种状态筛选列表， 列表显示筛选状态的数据。
        </div>
        <img
          style="margin: 20px 0;  box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/screen.png')"
          alt=""
        />
        <div class="title">按属性分类</div>
        <div class="content" style="margin-top: 10px">
          用户使用频次较高的区域，主要用于标签页、搜索、条件分类设置、有效帮助用户更精准的使用表格
        </div>
      </div>
    </div>
    <div class="add_box" style="margin-top: 100px">
      <div class="add_left">
        <div style="">
          <span class="yuan"></span>
          <span class="yuan_title">资产管理</span>
        </div>
        <img
          style="width: 100%; margin-top: 20px;  box-shadow: 0px 0px 20px 0px rgba(214, 221, 231, 1)"
          :src="require('@/assets/images/province/assessList.png')"
          alt=""
        />
      </div>
      <div class="add_right">
        <div class="title" style="margin-top: 49px">概括</div>
        <div class="content" style="margin: 10px 0 50px 0">
          资产管理分为通用资产，资产借出，资产归还，库存盘点，通用资产明确资产分类，资产借出，归还，方便了需要借用资产单位，以及借出后的归还，流程一借一出，简单明了，库存盘点，明确指导资产的库存数量。
        </div>
        <div class="title">效率</div>
        <div class="content" style="margin-top: 10px">
          准确有效的统计各个借用单位资产借出、归还多少，提高了工作效率，减少了流程的冗杂，节省了时间。
        </div>
      </div>
    </div>
<!--    <div class="footer_button">-->
<!--      <el-button @click="toHome">返回首页</el-button>-->
<!--      <el-button style="margin-left: 30px" @click="checkMore"-->
<!--        >查看更多</el-button-->
<!--      >-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #0052d9;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.content {
  font-size: 18px;
  color: #333333;
}

.exhibition_box {
  margin-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .exhibition_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .add_box {
    display: flex;
    justify-content: space-between;
    .add_left {
      width: 45%;
    }
    .add_right {
      width: 45%;
    }
  }
}
.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
