<template>
  <div class="w1200">
    <div class="guan_box">
      <div class="left">
        <div class="title">订车宝官网</div>
        <div style="font-size: 18px; margin-top: 20px">
          订车宝打造为中国广大汽车消费者提供买车、用车、养车及与汽车生活相关的全程服务，以全面、专业、可信赖、高互动性的内容，多层次、多维度地影响最广泛的汽车消费者，是中国最具价值的互联网汽车品牌传播和营销平台
        </div>
        <div class="img_box">
          <img
            style="width: 100%; margin-top: 130px; z-index: 999"
            :src="require('@/assets/images/orderCar/computerLeft.png')"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <img
          style="width: 80%; position: absolute; right: 0; top: 93px"
          :src="require('@/assets/images/orderCar/right.png')"
          alt=""
        />
      </div>
    </div>
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.guan_box {
  padding-top: 180px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    color: #333333;
    font-family: PingFangSC-Medium, PingFang SC;
    .title {
      font-size: 36px;
      font-weight: 500;
    }
    .img_box {
      height: 462px;
      background: url("../../../../assets/images/orderCar/left.png") no-repeat;
      background-size: 239px 157px;
      background-position: left bottom;
    }
  }
  .right {
    width: 70%;
    position: relative;
  }
  .footer {
    height: 160px;
  }
}
.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
