<template>
  <div class="w1200" style="margin-top: 100px">
    <div class="background_box">
      <div class="background_left">
        <img
          style=""
          :src="require('@/assets/images/cardHorizon/logo.png')"
          alt=""
        />
        <div class="ball" style="margin-top: 74px">
          <span class="ballNumber">1</span>
          <span class="ballTranslate">background</span>
        </div>
        <div class="contentTitle">项目背景</div>
        <div class="content" style="margin-top: 72px">
          车视界是一家专注于制作原创汽车类短视频互动的新媒体。公司旗下有车视界科技APP、网站等传播平台。APP是以汽车短视频、精彩图文为主的互动平台。是一款购车、用车、分享和记录有趣生活的社交APP。
        </div>
        <img
          style=""
          :src="require('@/assets/images/cardHorizon/place.png')"
          alt=""
        />
        <div class="product">
          <div class="content">
            车视界制作了多部汽车类优秀短视频，广受好评，不到一年时间原创短视频单期平均播放量200万+、全平台用户数800万+、单期播放量最高过1000万、总播放量突破10亿。
          </div>
        </div>
      </div>
      <div class="background_right">
        <img
          style="width:100%;"
          :src="require('@/assets/images/cardHorizon/abouct.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.ballNumber {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background: #f74f53;
}
.ballTranslate {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(247, 79, 83, 0.1);
}
.contentTitle {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.content {
  font-size: 16px;
  font-weight: 400;
}

.background_box {
  display: flex;
  justify-content: space-between;
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  color: #333333;
  .background_left {
    width: 40%;
    .product {
      height: 216px;
      background: url("../../../../assets/images/cardHorizon/gong.png")
        no-repeat;
      background-size: 370px 243px;
      background-position: left bottom;
    }
  }
  .background_right {
    width: 55%;
  }
}
</style>
