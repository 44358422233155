<template>
  <div class="exhibition_box w1200">
    <div class="exhibition_title">项目展示</div>
    <div class="memberManage">
      <div>
        <span class="yuan"></span>
        <span class="yuan_title">会员管理</span>
      </div>
      <div class="member_box">
        <div class="member_left">
          <p class="yuan_title">新增会员</p>
          <p class="box_content">
            根据需求规划，智慧工会系统共分为八大流程模块及若干重要功能模块的便捷化实现与处理。部分功能涉及多个功能模块，为保证项目后续的实用性、可持续迭代性及使用中可靠并发性能，能保证处理较高压力下的业务量，保障该套系统是一个健康、稳定、高性能系统。
          </p>
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/addmember.png')"
            alt=""
          />
        </div>
        <div class="member_cont">
          <img
            class="member_line"
            :src="require('@/assets/images/LabourUnion/memberline.png')"
            alt=""
          />
        </div>
        <div class="member_right">
          <p class="yuan_title">数据列表</p>
          <p class="box_content">
            这里的列表主要陈述标准的表格形式，表格被公认为是展现数据最为清晰、高效的形式之一，和搜索、筛选、分页等其他元素一起协同，适用于信息的收藏和展示，以及操作结构化数据，大大提升了用户对信息的接收效率和理解程度
          </p>
          <img
            style="margin-top: 24px; width: 100%"
            :src="require('@/assets/images/LabourUnion/memberlist.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 组织活动 -->
    <div class="activity_box">
      <div>
        <span class="yuan"></span>
        <span class="yuan_title">组织活动</span>
      </div>
      <div class="activity_list">
        <div class="activity_left">
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/memberlist.png')"
            alt=""
          />
          <img
            class="search_line"
            :src="require('@/assets/images/LabourUnion/searchline.png')"
            alt=""
          />
          <img
            class="page_line"
            :src="require('@/assets/images/LabourUnion/pageline.png')"
            alt=""
          />
        </div>
        <div class="activity_right">
          <div class="yuan_title" style="font-size: 20px">
            对数据进行分类，提高数据查看效率
          </div>
          <div class="box_content">
            表格属于频繁出现的业务模块，是实用多维度数据进行统一的结构展示，让用户清晰的看到在同一主题下的多条数据对比，同时保证数据的完整性。
          </div>
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/search.png')"
            alt=""
          />
          <div class="yuan_title" style="font-size: 20px">按属性分类</div>
          <div class="box_content">
            用户使用频次较高的区域，主要用于标签页、搜索、条件分类设置、有效帮助用户更精准的使用表格
          </div>
          <div class="yuan_title" style="font-size: 20px; margin-top: 5px">
            按数量分组
          </div>
          <div class="box_content">
            分页器作为局部导航组件，可以在数据较大的业务环境下使用
          </div>
        </div>
      </div>
    </div>
    <!-- 问卷调查 -->
    <div class="questionnaire">
      <div>
        <span class="yuan"></span>
        <span class="yuan_title">调查问卷</span>
      </div>
      <div class="questionnaire_box">
        <div class="questionnaire_left">
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/questronList.png')"
            alt=""
          />
          <div class="yuan_title" style="font-size: 18px; margin-top: 30px">
            内容区
          </div>
          <div class="box_content">
            在栅格的基础上，合理统一的页面布局有利于降低用户学习成本，快速的投入到工作中。
          </div>
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/questionTonent.png')"
            alt=""
          />

          <img
            class="analysis_line"
            :src="require('@/assets/images/LabourUnion/analysisLine.png')"
            alt=""
          />
          <img
            class="addques_line"
            :src="require('@/assets/images/LabourUnion/addquesline.png')"
            alt=""
          />
          <img
            class="content_line"
            :src="require('@/assets/images/LabourUnion/contentline.png')"
            alt=""
          />
        </div>
        <div class="questionnaire_right">
          <div class="yuan_title" style="font-size: 20px; margin-top: 60px">
            统计分析
          </div>
          <div class="box_content">点击问卷分析，对参与答次问卷的进行分析</div>
          <img
            style="width: 100%"
            :src="require('@/assets/images/LabourUnion/statistics.png')"
            alt=""
          />
          <div class="yuan_title" style="font-size: 20px; margin-top: 60px">
            新建问卷
          </div>
          <div class="box_content">
            动态创建评标方案，为每个产品划定多个标签和属性，根据每个属性创建和计算分值权重，以保证评分结果准确，创建完评标方案后无需人工干预即可完成之后流程，汇总总分排名。
          </div>
          <img
            style="width: 100%; margin-top: 30px"
            :src="require('@/assets/images/LabourUnion/addquestion.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 项目优势 -->
    <div class="advantage_box">
      <div>
        <span class="yuan"></span>
        <span class="yuan_title">项目优势</span>
      </div>
      <img
        style="width: 94%; margin-top: 30px; margin-left: 2%"
        :src="require('@/assets/images/LabourUnion/timeline.png')"
        alt=""
      />
      <div class="timeContent">
        <div class="timeLeft">简化工作流程 高效完成工作</div>
        <div class="timeLeft">人工智能辅助 自动完成工作</div>
        <div class="timeLeft">打开电话手机 随时随地办公</div>
        <div class="timeLeft">云端数据存储 安全不惧遗失</div>
      </div>

      <img
        style="width: 100%; margin: 130px 0"
        :src="require('@/assets/images/LabourUnion/generalization.png')"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #46a0fc;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}
.box_content {
  font-size: 18px;
  font-weight: 400;
}

.exhibition_box {
  margin-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .exhibition_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .memberManage {
  }
  .member_box {
    display: flex;
    justify-content: space-between;
    .member_left {
      width: 45%;
    }
    .member_cont {
      width: 10%;
      position: relative;
      .member_line {
        width: 200%;
        position: absolute;
        top: 30%;
        left: -50%;
      }
    }
    .member_right {
      width: 45%;
    }
  }
  .activity_box {
    margin-top: 70px;
    .activity_list {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .activity_left {
        width: 45%;
        position: relative;
        .search_line {
          position: absolute;
          top: 61px;
          left: 416px;
        }
        .page_line {
          position: absolute;
          top: 253px;
          left: 350px;
          width: 309px;
        }
      }
      .activity_right {
        width: 45%;
      }
    }
  }
  .questionnaire {
    margin-top: 70px;
    .questionnaire_box {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .questionnaire_left {
        width: 45%;
        position: relative;
        .analysis_line {
          position: absolute;
          top: 70px;
          left: 405px;
        }
        .addques_line {
          position: absolute;
          top: 36px;
          left: 405px;
        }
        .content_line {
          position: absolute;
          top: 236px;
          left: 23px;
        }
      }
      .questionnaire_right {
        width: 45%;
      }
    }
  }
  .advantage_box {
    margin-top: 70px;
    .timeContent {
      display: flex;
      justify-content: space-between;
      .timeLeft {
        width: 108px;
      }
    }
  }
}
</style>
