<template>
  <div class="exhibition_box">
    <div class="exhibition_title">项目展示</div>
    <div class="enterprise">
      <div class="enterprise_left">
        <div>
          <span class="enterprise_yuan"></span>
          <span class="enterprise_title">企业排放数据</span>
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          概括
        </div>
        <div style="width: 100%; font-size: 18px; margin-bottom: 34px">
          如图所示，企业排放数据页面，显示企业的历史排放数据记录。支持通过日期进行筛选。
        </div>
        <img
          style="width: 100%"
          :src="require('@/assets/images/meteorological/discharge.png')"
          alt=""
        />
      </div>
      <div class="enterprise_right">
        <div class="line_statistics">
          <div class="enterprise_image">
            <div class="enterprise_content">
              <div>折线统计图</div>
              <div style="font-size: 18px; font-weight: 400; margin-top: 5px">
                使用折线统计图总结了最大值、最小值、平均值、<br />
                折线图显示企业该日排放数据和用电量数值：排放数据和用电量通过各地环保<br />局接入后获取。X轴显示该日0-当前时间，Y轴显示单位。
              </div>
            </div>
          </div>
        </div>
        <div class="enterprise_table">
          <p style="font-size: 20px; font-weight: 500">表格</p>
          <p style="font-size: 18px; font-weight: 400">
            表格显示该日0-24小时时间，接口名称，排放物浓度，排放物排放量，折算浓<br />度，用电量。0-24小时时间为获取数据时间，接口名称，排放物浓度，排放物排<br />放量，折算浓度，用电量都通过各地环保局接入后获取显示。
          </p>
        </div>
      </div>
    </div>
    <!-- 污染来源追因 -->
    <div class="contaminated_box">
      <div class="contaminated_left">
        <div>
          <span class="contaminated_yuan"></span>
          <span class="contaminated_title">污染来源追因</span>
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          概括
        </div>
        <div style="width: 100%; font-size: 18px; margin-bottom: 34px">
          地图显示本地和外地对站点污染的占比以及各污染源类型在本地与外地的占比。
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          地图
        </div>
        <div style="font-size: 18px; margin-bottom: 34px">
          地图上显示本地地级市名称与占比的外地地级市名称，根据不同的百分比显示不同颜色，30%一下为绿色，30-60%为黄色，60以上为红色。地图不支持放大缩小。
        </div>
        <img
          style="width: 100%"
          :src="require('@/assets/images/meteorological/map.png')"
          alt=""
        />
      </div>
      <div class="contaminated_right">
        <img
          style="width: 70%"
          :src="require('@/assets/images/meteorological/questron.png')"
          alt=""
        />
        <div style="width: 30%">
          <div style="margin-top: 30px">
            <p>浮层</p>
            <p>
              支持通过日期，站点，污染物类型筛选，日期选择为单日，站点为辖区内
              站点，污染物类型为六项污染物。选择后点击立即查询按钮获取数据。
            </p>
          </div>
          <div style="margin-top: 140px">
            <p>
              浮层显示来源追因统计数据，统计分为：区域贡献统计，行业贡献统计两种。
              区域贡献统计，显示本市与外市名称，污染占比，通过柱状图显示各污染源在所在市的占比。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 决策生成 -->
    <div class="decision">
      <div class="decision_left">
        <div>
          <span class="yuan"></span>
          <span class="yuan_title">决策生成</span>
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          概括
        </div>
        <div style="width: 100%; font-size: 18px; margin-bottom: 34px">
          地图显示本地和外地对站点污染的占比以及各污染源类型在本地与外地的占比。
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          地图
        </div>
        <div style="font-size: 18px; margin-bottom: 34px">
          地图上显示本地地级市名称与占比的外地地级市名称，根据不同的百分比显示不同颜色，30%一下为绿色，30-60%为黄色，60以上为红色。地图不支持放大缩小
        </div>
        <img
          style="width: 100%"
          :src="require('@/assets/images/meteorological/policy.png')"
          alt=""
        />
      </div>
      <div class="decision_right">
        <div class="tab_box">
          <img
            style="width: 33.3%"
            :src="require('@/assets/images/meteorological/tab1.png')"
            alt=""
          />
          <img
            style="width: 33.3%"
            :src="require('@/assets/images/meteorological/tab2.png')"
            alt=""
          />
          <img
            style="width: 33.3%"
            :src="require('@/assets/images/meteorological/tab3.png')"
            alt=""
          />
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          概括
        </div>
        <div style="width: 100%; font-size: 18px; margin-bottom: 34px">
          地图显示本地和外地对站点污染的占比以及各污染源类型在本地与外地的占比。
        </div>
        <div style="font-weight: 500; font-size: 20px; margin: 10px 0">
          地图
        </div>
        <div style="font-size: 18px; margin-bottom: 34px">
          地图上显示本地地级市名称与占比的外地地级市名称，根据不同的百分比显示不同颜色，30%一下为绿色，30-60%为黄色，60以上为红色。地图不支持放大缩小
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50%;
  background: #5dc42e;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}

.exhibition_box {
  padding: 100px 0 130px;
  width: 1200px;
  margin: 0 auto;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .exhibition_title {
    border-left: 4px solid #5dc42e;
    padding-left: 4px;
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .enterprise {
    display: flex;
    justify-content: space-between;
    .enterprise_left {
      width: 45%;
      .enterprise_yuan {
        .yuan;
      }
      .enterprise_title {
        .yuan_title;
      }
    }
    .enterprise_right {
      width: 45%;
      .line_statistics {
        position: relative;
        width: 100%;
        height: 365px;
        box-shadow: 0px 0px 20px 0px #d6dde7;

        .enterprise_image {
          width: 100%;
          height: 100%;
          background: url("../../../../assets/images/meteorological/statistics.png")
            no-repeat center;
          .enterprise_content {
            position: absolute;
            left: 24px;
            top: 220px;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
      .enterprise_table {
        width: 100%;
        padding: 40px 10px 0 30px;
      }
    }
  }
  .contaminated_box {
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    margin-top: 60px;
    .contaminated_left {
      width: 45%;
      .contaminated_yuan {
        .yuan;
      }
      .contaminated_title {
        .yuan_title;
      }
    }
    .contaminated_right {
      width: 45%;
      display: flex;
      justify-content: space-between;
    }
  }

  .decision {
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    margin-top: 60px;
    .decision_left {
      width: 45%;
    }
    .decision_right {
      width: 45%;
      .tab_box {
        display: flex;
        justify-content: space-between;
        margin-top: 238px;
      }
    }
  }
}
</style>
