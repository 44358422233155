<template>
  <div class="assembly_box">
    <div class="bg">
      <div class="assembly_title">常用组件</div>
      <div
        style="display: flex; justify-content: space-between;margin: 30px 0;"
      >
        <img
          style="width: 435px;"
          :src="require('@/assets/images/meteorological/company.png')"
          alt=""
        />
        <img
          style="width: 220px;"
          :src="require('@/assets/images/meteorological/wind.png')"
          alt=""
        />
        <img
          style="width: 465px;"
          :src="require('@/assets/images/meteorological/aqi.png')"
          alt=""
        />
      </div>
      <div
        style="display: flex; justify-content: space-between;"
      >
        <img
          :src="require('@/assets/images/meteorological/line.png')"
          alt=""
          style="width: 800px"
        />
        <img
          style="width: 170px"
          :src="require('@/assets/images/meteorological/legend.png')"
          alt=""
        />
        <div style="width: 190px;display: flex;flex-direction: column;justify-content: space-between;">
          <img
            style="width: 100%"
            :src="require('@/assets/images/meteorological/radar.png')"
            alt=""
          />
          <img style="width: 100%" :src="require('@/assets/images/meteorological/pm25.png')" alt="" />
        </div>
      </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.assembly_box {
  background: rgba(93, 196, 46, 0.1);
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 40px 0 95px 0;
  .bg {
    width: 1200px;
    margin: 0 auto;
  }
  .assembly_title {
    border-left: 2px solid #5dc42e;
    font-size: 20px;
    font-weight: 500;
    color: #343449;
    line-height: 20px;
    padding-left: 5px;
  }
}
</style>
