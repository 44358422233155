<template>
    <div class="blockchain">
        <div class="banner w_banner">
            <Header :menuActive = "4"></Header>
            <div class="banner_title">区块链技术开发</div>
            <div class="banner_sub_title">ETH、OKchain、HECO、Trx等生态链开发，公链+DEX=DeFi生态</div>
        </div>
        <div class="main">
            <div class="main_system">
                <div class="w_main_title">区块链技术服务体系</div>
                <div class="w_main_sub_title">提供高性能、安全可靠的区块链技术平台服务</div>
                <div class="w_list w1200">
                    <div v-for="item in sysList" :key="item.title" class="list_item">
                      <div class="item_mask">
                        <img :src="item.icon" class="item_icon">
                      </div>
                        <div class="item_word">
                            <div class="word_title">{{ item.title }}</div>
                            <div class="word_line line1">{{ item.line1 }}</div>
                            <div v-if="item.line2" class="word_line line2">{{ item.line2 }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_tec">
                <div class="w_main_title">我们的技术优势</div>
                <div class="w_list w1200">
                    <div v-for="item in tecList" :key="item.title" class="list_item">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_c">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="main_process">
                <process></process>
            </div>
            <div class="main_partner">
                <partner></partner>
            </div>
        </div>
        <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import partner from '@/components/partner'
import process from '@/components/process'
export default {
    name: 'blockchain',
    components: {
        Header,
        Footer,
        partner,
        process
    },
    data() {
        return {
            sysList: [
                {
                    icon: require('@/assets/images/blockchain/icon1.png'),
                    title: '底层区块链',
                    line1: '1、超级节点技术。',
                    line2: '2、利用超级计算机和分布式架构，具备计算力和储存力。智能合约和核心架构分离技术，合约支持C++、GO、Solidity等多种语言。'
                },{
                    icon: require('@/assets/images/blockchain/icon2.png'),
                    title: '共识区块链BaaS',
                    line1: '1、采用私有化部署同样的底层。 ',
                    line2: '2、中心化平台搭建链，可根据业务新建合约快捷部署。'
                },{
                    icon: require('@/assets/images/blockchain/icon3.png'),
                    title: '可信数据服务平台',
                    line1: '帮助多方企业快速搭建数据共享、交换、流转通道，让客户专注于挖掘数据价值，让复杂的数据信息不再成为瓶颈。'
                },{
                    icon: require('@/assets/images/blockchain/icon4.png'),
                    title: '区块链溯源系统',
                    line1: '随着时代的进步，人们对质量安全的重视程度越来越高，很多行业兴起了溯源的浪潮，区块链溯源全程留痕数据不可修改，让溯源更加可信。'
                }
            ],
            tecList: [
                { 
                    title: '量化交易系统开发', 
                    content: '灵活的数据策略，同时支持上千交易对即可手动编辑又有全自动的交易策略，一键托管让小百叶可以快速上手。获取行情低延迟，不放过任何获利时机。然后将这个模块移到第一个。' 
                },{ 
                    title: '公链开发', 
                    content: '平台可以为用户提供防伪溯源、通证发行、共享账本、供应链管理等服务。企业无需高额开发成本，轻松定制最合适企业的区块链系统。' 
                },{ 
                    title: '智能合约开发', 
                    content: '将托管、维护、执行和交割等厚度按流程自动化，意味着合约双方都无须等待手动输入数据、交易对手旅行和余额指责或中间方处理交易。' 
                },{ 
                    title: '量化交易软件开发', 
                    content: '支持多种策略量化交易、深度交易、金融大数据抓取。' 
                },{ 
                    title: '区块链商城', 
                    content: '结合区块链特性，降低商品伪造或低质量的风险，保护供应链体系中各方利益。通过分布式记录，让供应链各参与方变得更加诚实、积极。' 
                },{ 
                    title: 'NFT开发', 
                    content: '版权存证是基于区块链技术不可篡改，可溯源，去中心化的特点，开发的功能。以此来记录交易信息，并对创作者派发数字身份，并生成Datakey，同时为每个作品派发数字ID，经过授权的用户有使用作品的权限。' 
                }
            ]
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
    }
}
</script>

<style lang="less">
    .blockchain {
        .banner {
            background: url(~@/assets/images/banner/bc.png) no-repeat center;
            text-align: center;
        }
        .main {
            .main_system {
                padding-top: 100px;
                .w_list {
                    margin-top: 10px;
                    flex-wrap: wrap;
                    .list_item {
                        width: 500px;
                        border: 1px solid #0052D9;
                        margin-top: 40px;
                        transition: all .5s;
                      &:hover {
                            box-shadow: 2px 18px 30px 0px rgba(0,82,217,0.2);
                          .item_icon {
                            transform: scale(1.1);
                          }
                        }
                      .item_mask{
                        width: 100%;
                        overflow: hidden;
                      }
                        .item_icon {
                            width: 100%;
                            transition: all .5s;
                        }
                        .item_word {
                            padding: 0 30px;
                            color: #333333;
                            line-height: 28px;
                            height: 180px;
                            .word_title {
                                margin: 27px 0;
                                font-size: 20px;
                                font-weight: bold;
                            }
                            .word_line {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .main_tec {
                margin-top: 100px;
                .w_list {
                    margin-top: 35px;
                    flex-wrap: wrap;
                    .list_item {
                        width: 560px;
                        margin-top: 25px;
                        background: #FFFFFF;
                        border: 1px solid #0052D9;
                        padding: 15px 28px;
                        .item_c {
                            margin-top: 28px;
                            text-align: justify;
                            text-justify: auto;
                        }
                    }
                }
            }
            .main_process {
                margin: 100px 0;
            }
        }
    }
</style>