<template>
    <div class="strike">
        <div class="banner w_banner">
            <Header :menuActive = "1" colorStyle="#ffffff"></Header>
            <img src="@/assets/images/case/banner.png" class="banner_img" />
        </div>
        <div class="main">
            <div class="main_intr">
                <img src="@/assets/images/case/strike.png" class="w1200">
            </div>
            <div class="main_partner">
                <partner></partner>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import partner from '@/components/partner'

export default {
    name: "CZTStandard",
    components: {
        Header,
        Footer,
        partner
    },
}
</script>

<style lang="less" scoped>
    .strike {
        .banner {
            background: url(~@/assets/images/banner/strike.png) no-repeat center;
            text-align: center;
            .banner_img {
                width: 1200px;
                margin: 35px auto 0;
            }
        }
        .main_intr {
            background: url(~@/assets/images/case/intr.png) no-repeat center;
            background-size: 100%;
            padding: 180px 0 145px 0;
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
    }
</style>