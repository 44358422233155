<template>
  <div class="w_partners">
    <div class="w1200">
      <img src="@/assets/images/home/aboutLogo.png" />
      <div class="about_ying">cooperative partner</div>
      <div class="about_title">合作伙伴</div>
    </div>
    <!-- <div class="w_main_sub_title">
            累计服务企业、单位超<span class="title_num">50+</span>家
    </div>-->
    <div class="pList pList1">
      <img
        v-for="(item, index) in pList1"
        :key="index"
        :src="item.icon"
        :style="{ width: item.width + 'px' }"
        class="list_Item"
      />
    </div>
    <div class="pList pList2">
      <img
        v-for="(item, index) in pList2"
        :key="index"
        :src="item.icon"
        :style="{ width: item.width + 'px' }"
        class="list_Item"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "partners",
  props: {},
  data() {
    return {
      pList1: [
        { icon: require("@/assets/images/home/p1.png"), width: 85 },
        { icon: require("@/assets/images/home/p2.png"), width: 185 },
        { icon: require("@/assets/images/home/p3.png"), width: 150 },
        { icon: require("@/assets/images/home/p4.png"), width: 315 }
      ],
      pList2: [
        { icon: require("@/assets/images/home/p5.png"), width: 185 },
        { icon: require("@/assets/images/home/p6.png"), width: 185 },
        { icon: require("@/assets/images/home/p7.png"), width: 495 }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.w_partners {
  padding: 80px 0;
  background: #f9fdff;
  .w_main_sub_title {
    .title_num {
      color: #0a58da;
    }
  }
  .pList {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pList1 {
    padding: 80px 0 65px;
  }
}
.about_ying {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 82, 217, 0.4);
}
.about_title {
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #243578;
}
</style>
