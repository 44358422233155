<template>
  <div>
    <banner></banner>
    <aboutproject />
    <vision />
    <assembly />
    <exhibition />
    <partner></partner>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import partner from "@/components/partner";
import banner from "@/views/case/meteorological/components/banner";
import aboutproject from "@/views/case/meteorological/components/aboutproject";
import vision from "@/views/case/meteorological/components/vision";
import assembly from "@/views/case/meteorological/components/assembly";
import exhibition from "@/views/case/meteorological/components/exhibition";

export default {
  name: "CZTStandard",
  components: {
    Footer,
    partner,
    banner,
    aboutproject,
    vision,
    assembly,
    exhibition
  },
};
</script>

<style scoped>
</style>