<template>
  <div class="banner">
    <Header :menuActive="1"></Header>
    <div class="content w1200">
      <div class="c-left">
        <p class="c_title">项目详情页</p>
        <h3>车视界科技APP</h3>
        <p class="c_point">更懂你的汽车视频</p>
        <span>购车、用车、分享和记录有趣生活的社交APP</span>
      </div>
      <!-- <div class="c_right">
        <img
          :src="require('@/assets/images/cardHorizon/rightbanner.png')"
          alt="项目示例图"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "banner",
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 620px;
  position: relative;
  background-image: url("../../../../assets/images/cardHorizon/rightbanner.png");
  padding-top: 36px;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    .c-left {
      width: 50%;
      color: #ffffff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-top: 100px;
      .c_title {
        font-size: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
        margin-bottom: 33px;
      }
      h3 {
        margin: 0;
        width: 430px;
        height: 80px;
        font-size: 50px;
        line-height: 40px;
        border-bottom: 1px solid #F74F53 ;
        padding-bottom: 20px;
        margin-bottom: 17px;
      }
      .c_point {
        margin: 0;
        font-size: 28px;
        color: #fff;
        margin-bottom: 13px;
      }
      span {
        font-size: 24px;
      }
    }
    .c_right {
        width: 50%;
        
      img {
        width: 618px;
        height: 311px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>