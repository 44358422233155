<template>
  <div class="centerM w1200">
    <div class="content_left">
      <div class="l_title">关于项目</div>
      <div v-for="item in deslist" :key="item.title" class="l_content">
        <p class="x_title">{{ item.title }}</p>
        <span class="x_des">{{ item.des }}</span>
      </div>
    </div>
    <div class="content_right">
      <img
        :src="require('@/assets/images/LabourUnion/about.png')"
        alt="项目示例图"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      deslist: [
        {
          title: "项目背景",
          des: "根据需求规划，智慧工会系统共分为八大流程模块及若干重要功能模块的便捷化实现与处理。部分功能涉及多个功能模块，为保证项目后续的实用性、可持续迭代性及使用中可靠并发性能，能保证处理较高压力下的业务量，保障该套系统是一个健康、稳定、高性能系统。",
        },
        {
          title: "项目效率",
          des: "本项目按照功能模块分为会员信息管理、教代会管理、投票选举、下属组织活动管理、健步走活动、教育主题活动学习及竞赛、问卷调查及活动接龙7个大模块，每个大模块内有若干个小模块。在项目设计与功能确立上遵循个性化与定制化原则。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.centerM {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  .content_left {
    width: 50%;
    color: #333333;
    font-family: PingFangSC-Semibold, PingFang SC;
    .l_title {
      border-left: 4px solid #388CFF ;
      padding-left: 4px;
      font-weight: 500;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .l_content {
      width: 80%;
      .x_title {
        font-size: 22px;
        font-weight: 600;
      }
      .x_des {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  .content_right {
    // width: 50%;
    // height: 524px;
    // background: linear-gradient(
    //   270deg,
    //   rgba(255, 255, 255, 0.4) 0%,
    //   rgba(93, 196, 46, 0.4) 100%
    // );
    // box-shadow: 0px 0px 20px 0px #d6dde7;
    // border-radius: 100px 0px 0px 100px;
    // backdrop-filter: blur(0px);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // img {
    //   width: 90%;
    // }
  }
}
</style>