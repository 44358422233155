<template>
  <div class="w1200" style="margin-top: 100px">
    <div class="design_box">
      <div class="design_left">
        <div class="ball" style="">
          <span class="ballNumber">3</span>
          <span class="ballTranslate">Design landing</span>
        </div>
        <div class="contentTitle" style="font-size: 32px">设计落地</div>
        <div class="size_box">
          <div class="size_title">常用字体</div>
          <div class="size_top">
            <div style="font-size: 10px">
              <p>苹方-简</p>
              <p>10pt</p>
            </div>
            <div style="font-size: 12px; margin-top: -7px" class="16pt mar20">
              <p>苹方-简</p>
              <p>12pt</p>
            </div>
            <div style="font-size: 14px; margin-top: -16px" class="18pt mar20">
              <p>苹方-简</p>
              <p>14pt</p>
            </div>
            <div style="font-size: 16px; margin-top: -24px" class="20pt mar20">
              <p>苹方-简</p>
              <p>16pt</p>
            </div>
          </div>
          <div class="contentTitle" style="margin-top: 20px">
            规范使用文字说明
          </div>
          <div class="content" style="margin-top: 10px">
            我们定义了几种常见的字体大小，通常情况下，仅允许使用这些规定的字体大小，如有特殊情况，要单独考虑。在多行显示文字的情况下能够清晰精准的表现层级关系。
          </div>
          <div>
            <div class="size_title" style="margin-top: 20px">常用颜色</div>
            <ul>
              <li v-for="item in colorList" :key="item.id">
                <div
                  class="color_box"
                  :style="{ background: item.color }"
                ></div>
                <div style="width: 100%; display: flex">
                  <span style="margin: 0px 20px 0px 16px">{{
                    item.color
                  }}</span>
                  <span>{{ item.content }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="design_right">
        <div class="size_title">图标设计</div>
        <div class="content" style="margin-top: 40px">
          底部tap栏图标：尺寸32×32pt，线性基础图标+APP主色调，可拓展且灵活，增强用户对品牌的认知。
        </div>
        <div class="icon">
          <ul class="iconBox">
            <li v-for="item in iconLiost" :key="item.id">
              <img
                style=""
                :src="require(`@/assets/images/cardHorizon/${item.path}.png`)"
                alt=""
              />
            </li>
          </ul>
          <div
            style="width: 50%; color: #f94c3d; padding-top: 43px"
            class="content"
          >
            <div>选中状态</div>
            <div style="margin-top: 45px">未选中状态</div>
          </div>
        </div>
        <div class="content" style="margin-top: 20px">
          金刚区图标：尺寸32×32pt，多色面型图标，使图标更有个性化，同时融入主题色，增强品牌认知感，视觉冲击力强。
        </div>
        <div class="jin">
          <ul class="jinBox">
            <li v-for="item in jinList" :key="item.id">
              <img
                style=""
                :src="
                  require(`@/assets/images/cardHorizon/jin${item.path}.png`)
                "
                alt=""
              />
            </li>
          </ul>
        </div>
        <div class="content" style="margin-top: 20px">
          我的页面图标：尺寸24×24pt，基础面型图标，采用差异化图标，会突出核心功能入口
        </div>
        <div class="jin">
          <ul class="jinBox">
            <li v-for="item in jinList" :key="item.id">
              <img
                style=""
                :src="
                  require(`@/assets/images/cardHorizon/xin${item.path}.png`)
                "
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="template">
      <div class="template_left">
        <div style="">
          <span class="yuan"></span>
          <span class="yuanTitle">组件</span>
        </div>
        <div style="margin-top: 30px">瓷片区</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem1.png')"
          alt=""
        />
        <div style="margin-top: 30px">选车型</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem2.png')"
          alt=""
        />
        <div style="margin-top: 30px">车系</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem3.png')"
          alt=""
        />
      </div>

      <div class="template_mid">
        <div style="margin-top: 55px">资讯</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem4.png')"
          alt=""
        />
        <div style="margin-top: 40px">直播</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem5.png')"
          alt=""
        />
      </div>
      <div class="template_right">
        <div style="margin-top: 55px">选经销商</div>
        <img
          style="margin-top: 20px;"
          :src="require('@/assets/images/cardHorizon/tem6.png')"
          alt=""
        />
        <div style="margin-top: 40px">栏目</div>
        <img
          style="margin-top: 20px"
          :src="require('@/assets/images/cardHorizon/tem6.png')"
          alt=""
        />
        <div class="contentTitle" style="margin-top: 40px">
          规范组件使用说明
        </div>
        <div class="content" style="margin-top: 10px">
          为了产品体验感一致，塑造品牌感摒弃利用多人协助，方便维护和更新产品，定制组件化使用规范。
        </div>
      </div>
    </div>
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      colorList: [
        {
          id: 1,
          color: "#F74F53",
          content: "APP主色调，用于按钮，图标，提示文字以及选中状态颜色",
        },
        {
          id: 2,
          color: "#00C3AC",
          content: "用于新能源页面按钮",
        },
        {
          id: 3,
          color: "##F7F8FB",
          content: "用于整个页面灰色底背景颜色",
        },
        {
          id: 4,
          color: "#F7F8FB",
          content: "用于车型库图标",
        },
        {
          id: 5,
          color: "#777777",
          content: "用于图标、内容、简介等",
        },
        {
          id: 6,
          color: "#343449",
          content: "用于标题，内容，图标下的文字等",
        },
      ],
      iconLiost: [
        {
          id: 1,
          path: "fang1",
        },
        {
          id: 2,
          path: "che1",
        },
        {
          id: 3,
          path: "tu1",
        },
        {
          id: 4,
          path: "zhang1",
        },
        {
          id: 5,
          path: "fang2",
        },
        {
          id: 6,
          path: "che2",
        },
        {
          id: 7,
          path: "tu2",
        },
        {
          id: 8,
          path: "zhang2",
        },
      ],
      jinList: [
        {
          id: 1,
          path: "1",
        },
        {
          id: 2,
          path: "2",
        },
        {
          id: 3,
          path: "3",
        },
        {
          id: 4,
          path: "4",
        },
      ],
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.ballNumber {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background: #f74f53;
}
.ballTranslate {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(247, 79, 83, 0.1);
}
.contentTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.content {
  font-size: 18px;
  font-weight: 400;
}
.mar20 {
  margin-left: 40px;
}
.size_title {
  border-left: 2px solid #f74f53;
  font-size: 20px;
  font-weight: 500;
  color: #343449;
  line-height: 20px;
  padding-left: 5px;
}
.yuan {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #f74f53;
  vertical-align: text-top;
}
.yuanTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #343449;
  margin-left: 4px;
}

.design_box {
  display: flex;
  justify-content: space-between;
  .design_left {
    width: 45%;
    .size_box {
      margin-top: 40px;
      .size_top {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
      }
      .color_box {
        width: 60px;
        height: 24px;
        border-radius: 4px;
      }
    }
  }
  .design_right {
    width: 45%;
    .icon {
      display: flex;
      justify-content: flex-start;
      .iconBox {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 25%;
        }
      }
    }
    .jinBox {
      display: flex;
      li {
        margin-right: 46px;
      }
    }
  }
}

.template {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  .template_left {
    width: 26%;
  }
  .template_mid {
    width: 26%;
  }
  .template_right {
    width: 26%;
  }
  img {
    width: 100%;
  }
}


</style>
