<template>
    <div class="banner">
        <Header :menuActive="1"></Header>
        <div class="content w1200">
            <div class="c-left">
                <p class="c_title">项目详情页</p>
                <h3>智慧工会管理系统</h3>
                <p class="c_point">实现灵活化办公</p>
                <span>统一安全 提升效率 提升体验 化简为繁</span>
            </div>
            <div class="c_right">
                <img :src="require('@/assets/images/LabourUnion/navBanner.png')" alt="项目示例图">
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header'
export default {
name: "banner",
    components: {
        Header,
    },
}
</script>

<style lang="less" scoped>
    .banner{
        width: 100%;
        height: 620px;
        background-image: url('../../../../assets/images/LabourUnion/banner.png');
        padding-top: 36px;
        .content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 35px;
            .c-left{
                color: #ffffff;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                margin-top: -20px;
                .c_title{
                    font-size: 22px;
                    height: 30px;
                    line-height: 30px;
                    margin: 0;
                    margin-bottom: 60px;
                }
                h3{
                    margin:0;
                    width: 648px;
                    height: 80px;
                    font-size: 50px;
                    line-height: 40px;
                    border-bottom: 1px solid #0052D9;
                    padding-bottom: 20px;
                    margin-bottom: 17px;
                }
                .c_point{
                    margin:0;
                    font-size: 28px;
                    color: #0052D9;
                    margin-bottom: 13px;
                }
                span{
                    font-size: 24px;
                }
            }
            .c_right{
                img{
                    width: 565px;
                    height: 400px;
                }
            }
        }
    }
</style>