<template>
  <div class="case">
    <div class="banner w_banner">
      <Header :menuActive="1" :colorStyle="colorStyle"></Header>
      <div class="w_banner">
        <div class="case_title">聚焦四大业务板块</div>
        <div class="case_content">公物仓管理平台 | 智慧工会 | APP | WEB</div>
        <div class="case_line"></div>
        <div class="case_content">以服务客户满意为根本并努力,超越客户的期望</div>
      </div>
    </div>
    <div class="gongwu_box">
      <div class="gongwu_title">公物仓管理平台</div>
      <div class="gongwu_content">公物仓的引领者</div>
      <div class="gongwu_line"></div>
      <div class="gongwu_man">
        <div class="yuan_box">
          <span class="yuan"></span>
          <span class="yuanSize">六大业务系统</span>
        </div>
        <div class="yuan_box">
          <span class="yuan"></span>
          <span class="yuanSize">提高业务办理效率</span>
        </div>
        <div class="yuan_box">
          <span class="yuan"></span>
          <span class="yuanSize">降低资产管理成本</span>
        </div>
        <div class="yuan_box">
          <span class="yuan"></span>
          <span class="yuanSize">部门数据共享互通</span>
        </div>
        <div class="yuan_box">
          <span class="yuan"></span>
          <span class="yuanSize">决策分析精确有效</span>
        </div>
      </div>
      <div @click="goTo()" class="gongwu_button">了解更多</div>
    </div>
    <div class="main">
      <div class="main_case">
        <div class="w_main_title">产品中心</div>
        <div class="w_list">
          <div v-for="item in list" :key="item.title" @click="toPath(item.id)" class="list_item">
            <div class="item_mask">
              <img :src="item.icon" class="item_icon" />
            </div>
            <div class="item_title">{{ item.title }}</div>
            <!-- <div class="item_time">{{ item.time }}</div> -->
          </div>
        </div>
      </div>
      <partner></partner>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import partner from "@/components/partner";

export default {
  name: "case",
  components: {
    Header,
    Footer,
    partner
  },
  data() {
    return {
      colorStyle: "#243578",
      list: [
        {
          icon: require("@/assets/images/icon/case11.png"),
          title: "打击有组织犯罪研判平台",
          time: "2022-08-29",
          id: "DJHEDetail"
        },
        {
          icon: require("@/assets/images/icon/case8.png"),
          title: "省级政府公物仓管理平台",
          time: "2022-08-29",
          id: "provinceManageDetil"
        },
        {
          icon: require("@/assets/images/icon/case9.png"),
          title: "空气质量预报预警管控平台",
          time: "2022-08-29",
          id: "meteorological"
        },
        {
          icon: require("@/assets/images/icon/case7.png"),
          title: "装备全寿命周期管理系统",
          time: "2022-08-29",
          id: "equipment"
        },
        {
          icon: require("@/assets/images/icon/case3.png"),
          title: "订车宝官网",
          time: "2022-08-29",
          id: "orderCar"
        },
        {
          icon: require("@/assets/images/icon/case6.png"),
          title: "智慧工会后台",
          time: "2022-08-29",
          id: "LabourUnion"
        },
        {
          icon: require("@/assets/images/icon/case4.png"),
          title: "智慧工会小程序",
          time: "2022-08-29",
          id: "wisdom"
        },
        {
          icon: require("@/assets/images/icon/case1.png"),
          title: "气象APP",
          time: "2022-08-29",
          id: "weather"
        },
        {
          icon: require("@/assets/images/icon/case2.png"),
          title: "车视界APP",
          time: "2022-08-29",
          id: "cardHorizon"
        },
        {
          icon: require("@/assets/images/icon/case5.png"),
          title: "订车宝APP",
          time: "2022-08-29",
          id: "orderCarApp"
        }
      ],
      moreList: [

      ]
    };
  },
  methods: {
    toPath(id) {
      this.$router.push({
        path: `/${id}`
      });
    },
    goTo() {
      this.$router.push({
        path: "/CZTStandardDetail"
      });
    }
  },
  created() {
    if (this.$route.query?.type) document.documentElement.scrollTop = 650;
  }
};
</script>

<style lang="less" scoped>
.case {
  .banner {
    background: url(~@/assets/images/banner/case.png) no-repeat center;
    color: #243578;
    .w_banner {
      padding-left: 204px;
      .case_title {
        margin-top: 120px;
        font-size: 64px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .case_content {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .case_line {
        width: 1px;
        height: 134px;
        border: 1px solid #243578;
        margin: 17px 0;
      }
    }
  }
  .gongwu_box {
    height: 1080px;
    padding-left: 204px;
    padding-top: 100px;
    background: url(~@/assets/images/banner/gongwu.png) no-repeat center;
    .gongwu_title {
      font-size: 64px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .gongwu_content {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #96bcff;
      margin-top: 30px;
    }
    .gongwu_line {
      width: 445px;
      height: 1px;
      border: 2px solid #ffffff;
      margin-top: 87px;
    }
    .gongwu_man {
      padding-top: 50px;
      .yuan_box {
        margin-bottom: 20px;
        .yuan {
          width: 28px;
          height: 28px;
          display: inline-block;
          border-radius: 50%;
          background: #ffffff;
        }
        .yuanSize {
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 18px;
        }
      }
    }
    .gongwu_button {
      width: 150px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #ffffff;
      text-align: center;
      line-height: 50px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 45px 0 0 45px;
      cursor: pointer;
    }
  }
  .main {
    .main_case {
      padding: 100px 0;

      .w_list {
        margin-top: 10px;
        flex-wrap: wrap;

        .list_item {
          width: calc(100% / 3);
          flex-shrink: 0;
          margin-top: 12px;
          cursor: pointer;
          padding: 10px;
          &:hover {
            img {
              transform: scale(1.1);
            }
            .item_title,
            .item_time {
              color: #0052d9;
            }
          }
          img {
            transition: all 0.5s;
          }
          .item_mask {
            width: 100%;
            height: 260px;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0px 2px 18px 12px rgba(0, 0, 0, 0.04);
          }
          .item_icon {
            width: 100%;
          }

          .item_title,
          .item_time {
            padding-left: 20px;
          }
          .item_title {
            font-size: 28px;
            line-height: 50px;
            margin-top: 10px;
          }

          .item_time {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
