<template>
  <div class="centerM w1200">
    <div class="content_left">
      <div class="l_title">关于项目</div>
      <div v-for="item in deslist" :key="item.title" class="l_content">
        <p class="x_title">{{ item.title }}</p>
        <span class="x_des">{{ item.des }}</span>
      </div>
    </div>
    <div class="content_right">
      <img :src="require('@/assets/images/icon/detail_center1.png')" alt="项目示例图" />
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      deslist: [
        {
          title: "项目背景",
          des:
            "公司自研公物仓管理平台，能提高资产管理中心办理业务的效率，方便有效的辅助各项业务的有效开展，通过管理平台建设可以直观查询各类业务的详细业务及办理情况，通过平台定的业务流程提高各部门之间的数据共享，实时互通。"
        },
        {
          title: "项目用户范围",
          des:
            "河南省直属行政事业单位、地市国有资产管理中心、地市公检法纪委、仓储管理中心、国有行政事业单位、第三方审计、评估、拍卖公司。"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.centerM {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  .content_left {
    width: 50%;
    color: #333333;
    font-family: PingFangSC-Semibold, PingFang SC;
    .l_title {
      border-left: 4px solid #388cff;
      padding-left: 4px;
      font-weight: 500;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .l_content {
      width: 80%;
      .x_title {
        font-size: 22px;
        font-weight: 600;
      }
      .x_des {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  .content_right {
    width: 50%;
    height: 524px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(56, 140, 255, 0.4) 100%
    );
    box-shadow: 0px 0px 20px 0px #d6dde7;
    border-radius: 100px 0px 0px 100px;
    backdrop-filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
</style>