<template>
    <div class="applets">
        <div class="banner w_banner">
            <Header :menuActive = "3"></Header>
            <div class="banner_title">微信-小程序开发</div>
            <div class="banner_sub_title">
                打破传统私人订制<br>
                抓住“8亿”流量红利！入口无处不在，功能丰富，随时随地访问，用完即走，推广简单
            </div>
        </div>
        <div class="main">
            <div class="main_why">
                <div class="w_main_title">为什么要做微信小程序开发？</div>
                <div class="w_list">
                    <div v-for="(item, index) in whyList" :key="index" class="list_item">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_c">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="main_fun">
                <div class="w_main_title">微信四大功能开发</div>
                <div class="fun_list w1200">
                    <div v-for="(item, index) in funList" :key="index" class="list_item">
                        <div class="item_mask">
                          <img :src="item.icon" class="item_icon">
                        </div>
                        <div class="item_word">
                            <div class="word_title word_item">{{ item.title }}</div>
                            <div class="word_line1 word_item">{{ item.line1 }}</div>
                            <div class="word_line2 word_item">{{ item.line2 }}</div>
                            <div class="word_btn word_item">
                                <div @click="goTo(index)" class="btn_word">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mani_build">
                <div class="w_main_title">系统平台搭建</div>
                <div class="w_main_sub_title">
                    全面开放用完即走&nbsp;&nbsp;|&nbsp;&nbsp;每个行业我们都有成熟的小程序解决方案
                </div>
                <div class="build_list build_list1 w1200">
                    <div v-for="(item, index) in buildList1" :key="item.name" class="list_item">
                        <img :src="item.icon" class="item_icon" :class="'icon_' + Number(index +1)">
                        <span class="item_name">{{ item.name }}</span>
                    </div>
                </div>
                <div class="build_list build_list2 w1200">
                    <div v-for="(item, index) in buildList2" :key="item.name" class="list_item">
                        <img :src="item.icon" class="item_icon" :class="'icon_' + Number(index +1)">
                        <span class="item_name">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <div class="main_process">
                <process></process>
            </div>
            <div class="main_partner">
                <partner></partner>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import partner from '@/components/partner'
import process from '@/components/process'
export default {
    name: 'applets',
    components: {
        Header,
        Footer,
        partner,
        process
    },
    data() {
        return {
            whyList: [
                { title: '大流量切口', content: '8亿微信用户，巨大的流量入口,快速分享微信流量红利' },
                { title: '互联网趋势', content: '移动互联网已超过传统互联网用户，成为了目前的主流' },
                { title: '用户粘性强', content: '人人都在使用微信，微信用户粘性和使用更强更利于营销' },
                { title: '人性化的营销方式', content: '是否接受信息全由用户自己做主提升了，用户体验和用户忠诚度' }
            ],
            funList: [
                {
                    icon: require('@/assets/images/applets/icon1.png'),
                    title: '小程序的特点',
                    line1: '人口无处不在，功能丰富，随时随地访问，用完即走，推广简单。',
                    line2: '适用于：酒店、餐饮、新零售、电商类、在线教育类等全行业'
                },{
                    icon: require('@/assets/images/applets/icon2.png'),
                    title: 'H5的特点',
                    line1: '开发简单、开发成本低、开发周期短、用户无需下载直接打开、传播力强。',
                    line2: '适用于：互联网、快销、金融、地产、旅游、广告等行业'
                },{
                    icon: require('@/assets/images/applets/icon3.png'),
                    title: '微信公众号的特点',
                    line1: '一对多传播、传播效率高、媒体内容丰富，可随时随地提供信息和服务，营销成本低。 ',
                    line2: '适用于：餐饮、医疗、教育、媒体娱乐、旅游等行业。'
                },{
                    icon: require('@/assets/images/applets/icon4.png'),
                    title: '微商城的特点',
                    line1: '线上线下覆盖广、受众精准、互动性强、投入广告成本低、自身功能强大、紧跟时尚潮流。 ',
                    line2: '适用于：百货商品、餐饮行业、美容美体、智能社区、婚庆影楼等行业。'
                }
            ],
            buildList1: [
                { icon: require('@/assets/images/applets/list1.png'), name: '地产' },
                { icon: require('@/assets/images/applets/list2.png'), name: '装饰' },
                { icon: require('@/assets/images/applets/list3.png'), name: '商店' },
                { icon: require('@/assets/images/applets/list4.png'), name: '酒店' },
                { icon: require('@/assets/images/applets/list5.png'), name: '银行' },
            ],
            buildList2: [
                { icon: require('@/assets/images/applets/list6.png'), name: '电子商务' },
                { icon: require('@/assets/images/applets/list7.png'), name: '餐饮' },
                { icon: require('@/assets/images/applets/list8.png'), name: '旅游' },
                { icon: require('@/assets/images/applets/list9.png'), name: '教育培训' },
                { icon: require('@/assets/images/applets/list10.png'), name: '医疗健康' },
            ],
        }
    },
    methods: {
        goTo(index) {
            this.$router.push({
                path: '/trait',
                query: {
                    type: index
                }
            })
        }
    }
}
</script>

<style lang="less">
    .applets {
        .banner {
            background: url(~@/assets/images/banner/applets.png) no-repeat center;
            text-align: center;
        }
        .main {
            .main_why {
                padding: 100px 0 160px;
                background: #F4FBFF;
                .w_list {
                    margin-top: 60px;
                    // transform-style: preserve-3d;
                    .list_item {
                        width: 25%;
                        height: 300px;
                        text-align: center;
                        padding-top: 100px;
                        color: #FFFFFF;
                        z-index: 98;
                        transition: all .5s;
                        &:hover {
                            box-shadow: 0px 6px 30px 0px #0050D4;
                            z-index: 99;
                            // transform: perspective(200px) translateZ(-30px);
                        }
                        &:nth-of-type(1) {
                            background: #0060FF;
                        }
                        &:nth-of-type(2) {
                            background: #2D7CFF;
                        }
                        &:nth-of-type(3) {
                            background: #5C99FF;
                        }
                        &:nth-of-type(4) {
                            background: #AACAFF;
                        }
                        .item_c {
                            margin-top: 30px;
                            padding: 0 30px;
                        }
                    }
                }
            }
            .main_fun {
                padding: 100px 0;
                .fun_list {
                    margin-top: 60px;
                    .list_item {
                        display: flex;
                        margin-top: 40px;
                        justify-content: space-between;
                        color: #333;
                        &:hover {
                            .item_icon{
                              transform: scale(1.1);
                            }
                            .word_title {
                                color: #0052D9
                            }
                            .btn_word {
                                background: #0052D9;
                                color: #ffffff!important;
                                &::after {
                                    border-top: 1px solid #ffffff!important;
                                    border-right: 1px solid #ffffff!important;
                                }
                            }
                        }
                      .item_mask{
                        width: 570px;
                        overflow: hidden;
                      }
                        .item_icon{
                            width: 100%;
                            height: 285px;
                            transition: all .5s;
                        }
                        .item_word {
                            .word_title {
                                font-size: 24px;
                                font-weight: bold;
                            }
                            .word_line1, .word_line2 {
                                font-size: 18px;
                                line-height: 28px;
                                margin-top: 20px;
                                text-align: justify;
                                text-justify: auto;
                            }
                            .word_item {
                                width: 420px;
                            }
                            .btn_word {
                                margin-top: 40px;
                                width: 130px;
                                height: 40px;
                                padding-left: 18px;
                                line-height: 38px;
                                border-radius: 21px;
                                border: 1px solid #0052D9;
                                font-size: 20px;
                                font-weight: 400;
                                color: #0052D9;
                                position: relative;
                                cursor: pointer;
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 16px;
                                    right: 18px;
                                    width: 8px;
                                    height: 8px;
                                    margin-left: 8px;
                                    border-top: 1px solid #0052D9;
                                    border-right: 1px solid #0052D9;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                        &:nth-of-type(1),&:nth-of-type(3) {
                            .item_word {
                                width: 570px;
                            }
                        }
                        &:nth-of-type(2),&:nth-of-type(4) {
                            flex-direction: row-reverse;
                            .item_word {
                                width: 600px;
                                padding-right: 30px;
                                .word_item {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }
            }
            .mani_build {
                height: 600px;
                background: linear-gradient(135deg, #BDDCFF 0%, #699EFF 100%);
                padding-top: 72px;
                color: #FFFFFF;
                .w_main_title {
                    width: 252px;
                    margin: 0 auto;
                    position: relative;
                    color: #ffffff;
                    &::before, &::after {
                        content: '';
                        position: absolute;
                        width: 290px;
                        height: 1px;
                        background: #0052D9;
                        top: 28px
                    }
                    &::before {
                        left: -365px;
                    }
                    &::after {
                        right: -365px
                    }
                }
                .w_main_sub_title {
                    color: #ffffff
                }
                .build_list1 {
                    margin-top: 45px;
                    .icon_1 {
                        width: 46px;
                    }
                    .icon_2 {
                        width: 60px
                    }
                    .icon_3 {
                        width: 42px
                    }
                    .icon_4 {
                        width: 48px;
                    }
                    .icon_5 {
                        width: 52px
                    }
                }
                .build_list2 {
                    margin-top: 65px;
                    .icon_1 {
                        width: 50px;
                    }
                    .icon_2 {
                        width: 54px
                    }
                    .icon_3 {
                        width: 52px
                    }
                    .icon_4 {
                        width: 48px;
                    }
                    .icon_5 {
                        width: 46px
                    }
                }
                .build_list {
                    display: flex;
                    justify-content: space-between;
                    .list_item {
                        display:flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 90px;
                        height: 95px;
                        .item_name {
                            font-size: 18px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .main_process {
                margin: 100px 0 160px
            }
        }
    }
</style>