<template>
  <div>
    <banner />
    <product />
    <partner></partner>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import partner from "@/components/partner";
import banner from "@/views/case/orderCarApp/components/banner";
import product from "@/views/case/orderCarApp/components/product";


export default {
  components: {
    Footer,
    partner,
    banner,
    product
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style scoped>
</style>
