<template>
  <div class="exhibition_box w1200">
    <div class="title">项目展示</div>
    <div class="exhibition_content">
      <div class="left">
        <div>
          <span class="yuan"></span>
          <span class="left_title">首页</span>
        </div>
        <div style="font-size: 20px; font-weight: 500; margin: 10px 0">首页设计</div>
        <div style="font-size: 18px">
          在栅格的基础上分割卡片，形成各个内容模块的容器以填充对应内容。可以满足业务人员、管理人员、决策人员所面临的的不同需求。
        </div>
        <div style="font-size: 20px; font-weight: 500; margin: 10px 0">数据图表</div>
        <div style="font-size: 18px; margin-bottom: 10px">
          对于模块中数据图表的展示，突出数据并弱化标签，让用户更好的聚焦于数据本身
        </div>
        <img style="width: 100%" :src="require('@/assets/images/icon/home.png')" alt="项目示例图"/>
        <img class="dash" :src="require('@/assets/images/icon/dash.png')" alt/>
      </div>
      <div class="right">
        <div class="broken_line">
          <img style="width: 95%" :src="require('@/assets/images/icon/line.png')" alt/>
          <div class="broken_title">折线统计图</div>
          <div
              class="broken_content"
          >总结了省厅移交案件、本级执法案件、通⽤资产借出、通⽤资产归还等⼯作进⾏集中管理、有效的统计了资产接收的数量、上缴的⾦额等，待办事项，提⾼了⼯作效率，减少了流程的冗杂，节省了时间。
          </div>
        </div>
        <div class="broken_bottom">
          <div>待办事项</div>
          <div>待办事项模块突出，提高工作效率，减少了流程的冗杂，节省时间。</div>
        </div>
      </div>
    </div>
    <!-- 通用资产模块 -->
    <div class="currency">
      <div class="currency_left">
        <span class="currency_top"></span>
        <span class="currency_font">通用资产管理系统</span>
        <div
            style="font-size: 18px; font-weight: 500; margin-top: 10px"
        >通用资产管理系统实现从资产“购置、出借、归还、调拨、维修、报废”全过程跟进管理，数字化库存盘点，解放人力，为组织实现资产智能管理。
        </div>
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/currency.png')"
            alt
        />
        <img class="addline" :src="require('@/assets/images/icon/addline.png')" alt/>
      </div>
      <div class="currency_right">
        <div class="right_top">
          <img style="width: 72%" :src="require('@/assets/images/icon/add.png')" alt/>
          <div class="right_message">
            <p>新增</p>
            <span>将新增的资产陈列在表格里方便统计、查看、对信息实现资源整合。</span>
          </div>
        </div>
        <div class="right_top">
          <img
              style="width: 72%; height: 265px"
              :src="require('@/assets/images/icon/addcontent.png')"
              alt
          />
          <div class="right_message">
            <p>内容区</p>
            <span>在栅格的基础上，合理统一的页面布局有利于降低用户学习成本，快速的投入到工作中。</span>
          </div>
        </div>
      </div>
    </div>
    <!--省厅委托罚没财物处置管理系统-->
    <div class="forfeiture">
      <div class="forfeiture_left">
        <span class="forfeiture_top"></span>
        <span class="forfeiture_font">省厅委托罚没财物处置管理系统</span>
        <div
            style="font-size: 18px; font-weight: 500; margin-top: 10px"
        >省厅委托罚没财物管理将省厅平台委托的罚没财物案件同步到系统内办理，并在办理过程中与省厅平台数据同步。同时将罚没移交财物处置操作，与本级执法执纪罚没移交财物处置操作保持一致，对罚没移交财物的审计、评估、拍卖信息一目了然。
        </div>
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/weitu1.png')"
            alt
        />
      </div>
      <div class="forfeiture_center" style="    margin-top: 255px;">
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/weitu2.png')"
            alt
        />
      </div>
      <div class="forfeiture_right" style="width: 45%">
        <img
            style="margin-top: 229px; width: 100%"
            :src="require('@/assets/images/icon/weitu3.png')"
            alt
        />

      </div>
    </div>

    <!--本级执法执纪财物处置管理系统-->
    <div class="forfeiture">
      <div class="forfeiture_left">
        <span class="forfeiture_top"></span>
        <span class="forfeiture_font">本级执法执纪财物处置管理系统</span>
        <div
            style="font-size: 18px; font-weight: 500; margin-top: 10px"
        >本级执法执纪罚没财物管理将线下办理业务提到线上，减少管理难度，节省时间，提高办事效率。同时提高对罚没移交财物的管理，做到了线下财物线上管理，对罚没财物类别，信息，储存等相关信息一目了然。
        </div>
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/weitu30.png')"
            alt
        />
      </div>
      <div class="forfeiture_center" style="    margin-top: 255px;">
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/weitu2.png')"
            alt
        />
      </div>
      <div class="forfeiture_right" style="width: 45%">
        <img
            style="margin-top: 208px; width: 100%"
            :src="require('@/assets/images/icon/weitu31.png')"
            alt
        />

      </div>
    </div>
<!--通用资产移交接收管理系统-->
    <div class="general">
      <div class="general_left">
        <span class="general_top"></span>
        <span class="general_font">通用资产移交接收管理系统</span>

      <div
          style="font-size: 18px; font-weight: 500; margin-top: 10px"
      >各单位移交通用资产至中心，实现资产的移交申请、查验接收、编号、入库，准确有效统计各单位移交资产状况。
      </div>
      <img
          style="margin-top: 70px; width: 100%"
          :src="require('@/assets/images/icon/weitu25.png')"
          alt
      />
      </div>


      <div class="general_right">
        <span class="general_top"></span>
        <span class="general_font">内部签报系统</span>

        <div
            style="font-size: 18px; font-weight: 500; margin-top: 10px"
        >签报用于单位内部流程审批，沟通协作；单位内部发起签报用于工作流程中的汇报、请示。
        </div>
        <img
            style="margin-top: 70px; width: 100%"
            :src="require('@/assets/images/icon/weitu24.png')"
            alt
        />
      </div>

    </div>
    <!--    &lt;!&ndash; 通用资产借出归还 &ndash;&gt;-->
    <!--    <div class="lendreturn">-->
    <!--      <div class="lendreturn_left">-->
    <!--        <span class="lendreturn_top"></span>-->
    <!--        <span class="lendreturn_font">通用资产-借出 归还</span>-->
    <!--        <div style="font-size: 18px; font-weight: 500; margin: 20px 0 10px 0">概括</div>-->
    <!--        <div-->
    <!--          style="font-size: 16px; font-weight: 400"-->
    <!--        >资产借出，归还，方便了需要借用资产单位，以及借出后的归还，流程一借一出，简单明了，库存盘点，明确指导资产的库存数量。</div>-->
    <!--        <img-->
    <!--          style="width: 100%; margin-top: 40px"-->
    <!--          :src="require('@/assets/images/icon/lend.png')"-->
    <!--          alt-->
    <!--        />-->
    <!--        <img-->
    <!--          style="width: 100%; margin-top: 40px"-->
    <!--          :src="require('@/assets/images/icon/return.png')"-->
    <!--          alt-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div class="lendreturn_right">-->
    <!--        <img-->
    <!--          style="width: 100%; margin-top: 120px"-->
    <!--          :src="require('@/assets/images/icon/search.png')"-->
    <!--          alt-->
    <!--        />-->
    <!--        <div style="font-size: 20px; font-weight: 500; margin-left: 15px">搜索框</div>-->
    <!--        <div-->
    <!--          style="-->
    <!--            font-size: 18px;-->
    <!--            font-weight: 400;-->
    <!--            margin-top: 10px;-->
    <!--            margin-left: 15px;-->
    <!--          "-->
    <!--        >支持根据借用编号/发起人/借用单位搜索，列表显示搜索内容的数据。</div>-->
    <!--        <img-->
    <!--          style="margin-top: 20px; height: 164px"-->
    <!--          :src="require('@/assets/images/icon/manage.png')"-->
    <!--          alt-->
    <!--        />-->
    <!--        <div style="font-size: 20px; font-weight: 500; margin-left: 15px">气泡窗口</div>-->
    <!--        <div-->
    <!--          style="-->
    <!--            font-size: 18px;-->
    <!--            font-weight: 400;-->
    <!--            margin-top: 10px;-->
    <!--            margin-left: 15px;-->
    <!--          "-->
    <!--        >-->
    <!--          支持根据待办理和已办理两种状态筛选列表， 列表显示筛选状态的数据。-->
    <!--          待办理显示流转到用户这里需要办理的借用申请；已办理显示用户已经办理过的借用申请。-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 罚没财物 &ndash;&gt;-->
    <!--    <div class="property">-->
    <!--      <div class="property_left">-->
    <!--        <span class="property_top"></span>-->
    <!--        <span class="property_font">罚没财物管理</span>-->
    <!--        <div style="font-size: 18px; font-weight: 400; margin-top: 10px">实现财政部门对办案机关移交罚没财物的处置</div>-->
    <!--        <div style="font-size: 20px; font-weight: 500; margin-top: 70px">公物仓平台财政部门处置资产的方式</div>-->
    <!--        <div style="margin-top: 10px">财政部门线上委托第三方机构进行审计、评估、拍卖，由第三方机构提交处置结果，财政部门审核第三方机构提交的结果。</div>-->
    <!--        <img class="property_line" :src="require('@/assets/images/icon/propertyLine.png')" alt />-->
    <!--      </div>-->
    <!--      <div class="property_right">-->
    <!--        <img style="width: 100%" :src="require('@/assets/images/icon/property.png')" alt />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 页面展示 &ndash;&gt;-->
    <!--    <div class="viewCheck">-->
    <!--      <div>-->
    <!--        <span class="viewCheck_title"></span>-->
    <!--        <span class="viewCheck_message">页面展示</span>-->
    <!--      </div>-->
    <!--      <div style="margin-top:10px;">-->
    <!--        <img style="width: 100%" :src="require('@/assets/images/icon/viewPage.png')" alt />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {
  },
  mounted() {
  },
  computed: {}
};
</script>
<style lang="less" scoped>
.yuan_box {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background: #0052d9;
}

.yuan_font {
  font-size: 26px;
  font-weight: 500;
  margin-left: 10px;
}

.lendBox {
}

.exhibition_box {
  padding-top: 60px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333;

  .title {
    border-left: 4px solid #388cff;
    padding-left: 4px;
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    margin-bottom: 40px;
  }

  .exhibition_content {
    display: flex;
    justify-content: space-between;

    .left {
      width: 45%;
      position: relative;

      .yuan {
        .yuan_box;
      }

      .left_title {
        .yuan_font;
      }

      .dash {
        position: absolute;
        width: 243px;
        top: 87%;
        z-index: 9999;
        left: 79%;
      }
    }

    .right {
      width: 45%;

      .broken_line {
        width: 100%;
        height: 362px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px #d6dde7;
        padding: 18px 0px 0 37px;

        .broken_title {
          font-size: 20px;
          font-weight: 500;
          padding-left: 20px;
        }

        .broken_content {
          font-size: 18px;
          font-weight: 400;
          padding: 20px;
        }
      }

      .broken_bottom {
        margin-top: 130px;
        margin-left: 20px;
      }
    }
  }

  .general {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    color: #333;
    position: relative;

    .general_left {
      width: 45%;

      .general_top {
        .yuan_box;
      }

      .general_font {
        .yuan_font;
      }
    }
    .general_right {
      width: 45%;

      .general_top {
        .yuan_box;
      }

      .general_font {
        .yuan_font;
      }
    }
  }

  .forfeiture {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    color: #333;
    position: relative;

    .forfeiture_left {
      width: 45%;

      .forfeiture_top {
        .yuan_box;
      }

      .forfeiture_font {
        .yuan_font;
      }
    }
  }
  .currency {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    color: #333;
    position: relative;

    .currency_left {
      width: 45%;

      .currency_top {
        .yuan_box;
      }

      .currency_font {
        .yuan_font;
      }

      .addline {
        position: absolute;
        z-index: 9998;
        top: 23%;
        left: 34%;
        width: 272px;
      }
    }

    .currency_right {
      width: 45%;

      .right_top {
        display: flex;
      }

      .right_message {
        p {
          font-size: 18px;
          font-weight: 500;
        }

        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .lendreturn {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    color: #333;

    .lendreturn_left {
      width: 45%;

      .lendreturn_top {
        .yuan_box;
      }

      .lendreturn_font {
        .yuan_font;
      }
    }

    .lendreturn_right {
      width: 45%;
    }
  }

  .property {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    .property_left {
      width: 45%;
      position: relative;

      .property_top {
        .yuan_box;
      }

      .property_font {
        .yuan_font;
      }

      .property_line {
        position: absolute;
        top: 46%;
        left: 65%;
        width: 320px;
        height: 40px;
      }
    }

    .property_right {
      width: 45%;
    }
  }

  .viewCheck {
    margin-top: 100px;

    .viewCheck_title {
      .yuan_box;
    }

    .viewCheck_message {
      .yuan_font;
    }
  }
}
</style>
