<template>
  <div class="centerM w1200">
    <div class="content_left">
      <div class="l_title">关于项目</div>
      <div v-for="item in deslist" :key="item.title" class="l_content">
        <p class="x_title">{{ item.title }}</p>
        <span class="x_des">{{ item.des }}</span>
      </div>
    </div>
    <div class="content_right">
      <img
        :src="require('@/assets/images/equipment/about.png')"
        alt="项目示例图"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      deslist: [
        {
          title: "项目背景",
          des: "现代管理越来越强调利用有形资产来提供优质服务的能力，即通过装备状态管理来确保有形资产物尽其用、安全运行，在希望的时间和地点提供需要的装备，同时尽可能地降低运行和维护成本。",
        },
        {
          title: "",
          des: "根据发展需要，为了充分发挥计算机装备资源的应用能力，更好的提高计算机装备的使用率及装备的保养需要，为更准确的对使用装备的各部门、人员及有关，为做出更节约成本、提升效率的装备购置计划提供依据",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.centerM {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  .content_left {
    width: 50%;
    color: #333333;
    font-family: PingFangSC-Semibold, PingFang SC;
    .l_title {
      border-left: 4px solid #388CFF ;
      padding-left: 4px;
      font-weight: 500;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .l_content {
      width: 80%;
      .x_title {
        font-size: 22px;
        font-weight: 600;
      }
      .x_des {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  .content_right {
    width: 50%;
    // height: 524px;
    // background: linear-gradient(
    //   270deg,
    //   rgba(255, 255, 255, 0.4) 0%,
    //   rgba(93, 196, 46, 0.4) 100%
    // );
    // box-shadow: 0px 0px 20px 0px #d6dde7;
    // border-radius: 100px 0px 0px 100px;
    // backdrop-filter: blur(0px);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    img {
      width: 104%;
    }
  }
}
</style>