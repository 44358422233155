<template>
  <div class="home">
    <div class="banner" :class="'banner_'+bannerActive">
      <Header :menuActive="0" :colorStyle="colorStyle"></Header>
      <el-carousel
          ref="carousel"
          :interval="4000"
          @setActiveItem="setActiveItem"
          arrow="never"
          class="banner_c"
          :height="screenheight"
          @change="(e) => bannerActive = e"
      >
        <el-carousel-item v-for="item in 4" :key="item">
          <div class="banner_box" v-if="bannerActive==0">
            <div class="banner_title">专注科技与创新</div>
            <div class="banner_content">诚信、创新、专注、专业</div>
            <img src="@/assets/images/banner/button.png" style="margin-top: 30px;" />
            <a @click="goTo('/customize')" class="word_btn">立即定制</a>
          </div>
          <div class="banner_box" v-if="bannerActive==1">
            <div class="banner_title" style="color: #5B7BDA;">公物仓建设的引领者</div>
            <div class="banner2_imgbox">
              <div>
                <img class="itemCalc" src="@/assets/images/banner/bg2img1.png" />
                <div class="content">标准化</div>
              </div>
              <div style="margin: 0 70px;">
                <img class="itemCalc" src="@/assets/images/banner/bg2img2.png" />
                <div class="content">智能化</div>
              </div>
              <div>
                <img class="itemCalc" src="@/assets/images/banner/bg2img3.png" />
                <div class="content">可视化</div>
              </div>
            </div>
            <a
                @click="goTo('/case')"
                class="word_btn"
                style="background: linear-gradient(90deg, #0165DD 0%, #69B7FE 100%);"
            >了解更多</a>
          </div>
          <div class="banner_box" v-if="bannerActive==2">
            <div class="banner_title">10年项目开发经验</div>
            <div class="banner_content">
              <span style="color: #FFB500;">200+</span>企业成功案例
            </div>
            <a
                @click="goTo('/customize')"
                class="word_btn"
                style="background: linear-gradient(90deg, #0165DD 0%, #69B7FE 100%);"
            >立即定制</a>
          </div>
          <div class="banner_box" v-if="bannerActive==3">
            <div class="banner_title" style="color:#002CD1;">系统平台搭建</div>
            <div class="banner_content" style="color:#0023A5;">加快信息化步伐 实现创新变革</div>
            <a
                @click="goTo('/customize')"
                class="word_btn"
                style="background: linear-gradient(90deg, #0165DD 0%, #69B7FE 100%);"
            >立即定制</a>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner_tip">
        <div
            v-for="(item, index) in [1,2,3,4]"
            @click="setActiveItem(index)"
            :key="index"
            class="tip_item"
            :class="{tip_active: index == bannerActive}"
        ></div>
      </div>
    </div>
    <div class="main_box">
      <div class="about_box w1200">
        <div class="about_left">
          <img src="@/assets/images/home/aboutLogo.png" />
          <div class="about_ying">About Zeshang</div>
          <div class="about_title">关于泽尚</div>
          <div class="home_title" style="margin-top:40px;">企业介绍</div>
          <div class="home_content">
            郑州泽尚网络科技有限公司是一家致力于科技推广和提供一站式解决方案的高新技术公司，是国内公物仓管理平台领域信息化解决方案的优质服务商,为用户提供包括信息咨询、方案规划、数据分析、系统集成及交付和技术服务在内的整体解决方案。
            <br />
            <br/>
            <br/>
            <span
                style="display: inline-block;"
            >公司目前已经开发岀具有自主知识产权的省级政府公物仓管理平台、省级政府公物仓罚没财物移交处置管理系统、省级政府公物仓通用资产管理系统、打击有组织犯罪信息研判系统、智慧工会系统等多软件产品，并服务了河南省内多家单位。泽尚科技将继续深耕企业级服务，持续专注于公物仓管理领域，在不断优化用户业务流程和体验的同时，进一步利用创新技术，为用户实现单位、资产与第三方之间的无缝连接与交互。</span>
          </div>
          <div class="home_title" style="margin-top:50px;">企业文化</div>
          <div class="home_content">
            企业愿景：成为公物仓信息化建设的引领者
            <br />企业使命：以效能提升信息化建设为己任，使公物仓管理更高效更安全
            <br />服务理念：以服务客户满意为根本并努力,超越客户的期望
            <br />核心价值：诚信、创新、专注、专业
            <br />
          </div>
        </div>
        <div class="about_right">
          <img src="@/assets/images/home/aboutRight.png" style=" width: 799px; height: 510px;" />
        </div>
      </div>
      <div style="background: linear-gradient(135deg, #FAFDFF 0%, #F5F5F6 100%); height: 313px;">
        <div class="w_list">
          <div v-for="item in aboutList" :key="item.title" class="list_item">
            <img :src="item.icon" class="item_icon" />
            <div class="item_title">{{ item.title }}</div>
            <div class="item_c">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <!-- 案例展示 -->
      <div class="case_box w1200">
        <img src="@/assets/images/home/aboutLogo.png" />
        <div class="about_ying">Case presentation</div>
        <div class="about_title">产品中心</div>
        <div class="case_list">
          <div v-for="item in caseList" :key="item.title" class="list_item">
            <div class="item_mask">
              <img :src="item.icon" class="item_icon" />
            </div>
            <div class="item_title">{{ item.title }}</div>
            <div class="item_c"  @click="goTo(item.path)">{{ item.content }}</div>
          </div>
        </div>
        <div class="case_more">
          <div @click="goTo('/case')" class="more_btn">更多产品</div>
        </div>
      </div>
      <!-- 服务支持 -->
      <div
          style="background: linear-gradient(135deg, #FAFDFF 0%, #F5F5F6 100%);padding:90px 0 200px 0;margin-top:70px;"
      >
        <div class="serve_box w1200">
          <img src="@/assets/images/home/aboutLogo.png" />
          <div class="about_ying">Service support</div>
          <div class="about_title">服务支持</div>
          <div class="serve_list">
            <div v-for="item in serviceList" :key="item.title" class="list_item">
              <div class="item_mask">
                <img :src="item.icon" class="item_icon" />
              </div>
              <div class="item_title">{{ item.title }}</div>
              <div class="item_c">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <partner style="background: #fff;"></partner>
    </div>
    <div class="float">
      <div class="f_item f1">
        <div class="item_show">
          <img src="@/assets/images/icon/icon_phone2.png" class="show_icon" />
          <div class="show_word">电话咨询</div>
        </div>
        <div class="item_hide">
          电话咨询
          <br />13521141981
        </div>
      </div>
      <div class="f_item f2">
        <div class="item_show">
          <img src="@/assets/images/icon/icon_wx.png" class="show_icon" />
          <div class="show_word">微信咨询</div>
        </div>
        <img src="@/assets/images/icon/icon_qr.png" class="item_hide" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import partner from "@/components/partner";
export default {
  name: "home",
  components: {
    Header,
    Footer,
    partner
  },
  data() {
    return {
      screenheight:document.documentElement.clientHeight-81 + 'px',
      colorStyle:"#243578",
      bannerList: [
        {
          icon: require("@/assets/images/banner/home.png"),
          title: "专注科技与创新",
          line: [
            "核心主创人员均来自大型互联网公司，",
            "有超过10年的大型系统、移动应用项目研发经验和团队管理经验。",
            "泽尚科技致力于结合当今前沿的信息技术，为客户量身定制互联网研发及产品解决方案。"
          ]
        },
        {
          icon: require("@/assets/images/banner/home2.png"),
          title: "系统平台搭建",
          line: [
            "无论是使用新兴技术、或利用定制开发能力或采用市场 先企业提供的成熟套装软件",
            "我们的系统开发服务团队都能基于规范的方法和流程， 新的解决方案快速扩展至现有系统和业务流程中",
            "为客户提供安全、稳定、高效的全方位系统服务，帮助 业加快信息化步伐，实现创新变革。"
          ]
        },
        {
          icon: require("@/assets/images/banner/home3.png"),
          title: "拥有10年项目开发经验",
          line: [
            "iOS、Andriod、PC、后台开发",
            "2000+企业成功案例，全行业开发解决方案提供商、给您一站式开发体验，一站式的品牌数字营销体系助力品牌全流程价值提升",
            "专注、高效、稳定、专注、十年直销开发经验让您放心",
            "快速广泛的一体化平台让互联网提升企业竞争力",
            "助力提升了流量，实现企业盈利"
          ]
        }
      ],
      bannerActive: 0,
      aboutList: [
        {
          icon: require("@/assets/images/home/about1.png"),
          title: "方案合理",
          content: "以上千家成功案例经验，量身定制合理的开发方案"
        },
        {
          icon: require("@/assets/images/home/about2.png"),
          title: "质量可靠",
          content: "遵照全球公认的CMM质量体系，精细打磨产品每一个细节"
        },

        {
          icon: require("@/assets/images/home/about4.png"),
          title: "服务贴心",
          content: "深入贯彻全民客服的理念，360度跟 进你的需求"
        } ,{
          icon: require("@/assets/images/home/about3.png"),
          title: "准时交付",
          content: "十多年开发经验的专业外包团队，精准把控整个开发流程"
        }
      ],
      serviceList: [
        {
          icon: require("@/assets/images/home/service1.png"),
          title: "APP开发",
          content: "专注高IOS Andriod系统开发提供优IOS Andriod开发服务"
        },
        {
          icon: require("@/assets/images/home/service2.png"),
          title: "小程序开发",
          content: "针对主流小程序系统提供全流程技术咨询、策划、开发服务"
        },
        {
          icon: require("@/assets/images/home/service3.png"),
          title: "web端开发",
          content: "针对企业需求，提供专业官网、分销等网页开发服务"
        },
        {
          icon: require("@/assets/images/home/service4.png"),
          title: "后台管理系统开发",
          content: "针对企业需求，提供专业B端，B2C等开发服务"
        }
      ],
      appList: [
        {
          icon: require("@/assets/images/home/app1.png"),
          title: "交通物流",
          content: "酒店预订/景点隔离/精确导航/机票预订/周边旅游等"
        },
        {
          icon: require("@/assets/images/home/app2.png"),
          title: "移动电商",
          content: "会员管理/商品展示/订单管理/加盟店管理/物流"
        },
        {
          icon: require("@/assets/images/home/app3.png"),
          title: "社交",
          content: "互动游戏/好友管理/定位搜索/表情管理/语音聊天/活动圈子"
        },
        {
          icon: require("@/assets/images/home/app4.png"),
          title: "政府门户",
          content: "政务服务/政民互动/政府信息公开/机构单位/电子监察管理"
        },
        {
          icon: require("@/assets/images/home/app5.png"),
          title: "房地产",
          content: "经纪人管理/关联地产公司/房源发布/预约看房/沙盘展示"
        },
        {
          icon: require("@/assets/images/home/app6.png"),
          title: "家政",
          content: "家政预约/家政工展示/在线支付/客服系统/客户评价"
        },
        {
          icon: require("@/assets/images/home/app7.png"),
          title: "餐饮",
          content: "餐饮加盟/餐厅搜索/在线支付/精准定位/积分系统"
        }
      ],
      appActive: 0,
      caseList: [
        {
          icon: require("@/assets/images/icon/bianzu73.png"),
          title: "公物仓管理平台",
          path:"/provinceManageDetil",
          content:
              "公物仓管理平台，能提供资产管理中心办理业务效率，方便高效的辅助各项业务有效开展。通过平台可以直观查询各类业务的详细业务及办理情况，通过平台提高各部门之间的数据共享、实时互通"
        },
        {
          icon: require("@/assets/images/icon/bianzu3.png"),
          title: "智慧工会",
          path: '/LabourUnion',
          content:
              "智慧工会，能提供工会会员信息管理，组织管理。能够在线发起教代会提案，完成教代会提案的审批与监督流程。通过智慧工会可以更方便查询各代表团、兴趣小组情况，发布学习资料与组织会员活动，发布工会福利。"
        },
        {
          icon: require("@/assets/images/icon/bianzu4.png"),
          title: "打击有组织犯罪研判平台",
          path: '/DJHEDetail',
          content:
              "打击有组织犯罪信息研判平台，利用大数据分析已录入的案件之间犯罪嫌疑人关系，更高效的挖掘出犯罪的团伙的特征。通过平台可以关注嫌疑人与案件，有利于追踪线索，组合线索。更可以下达指令给分局完成与分局的数据共享互通。"
        }
      ],
      joinImgList: [
        { icon: require("@/assets/images/home/joinImg1.png") },
        { icon: require("@/assets/images/home/joinImg2.png") },
        { icon: require("@/assets/images/home/joinImg3.png") },
        { icon: require("@/assets/images/home/joinImg4.png") }
      ],
      joinList: [
        {
          icon: require("@/assets/images/home/join1.png"),
          title: "科技定位",
          content: "企业信息化整体解决方案的专家"
        },
        {
          icon: require("@/assets/images/home/join2.png"),
          title: "科技理念",
          content: "联合运营、整体营销"
        },
        {
          icon: require("@/assets/images/home/join3.png"),
          title: "科技使命",
          content: "国内率先开创了以运营模 式实现企业信息化服务的 先河"
        },
        {
          icon: require("@/assets/images/home/join4.png"),
          title: "科技愿景",
          content: "成为推动中国电子商务的 核心力量"
        }
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    window.onresize = () => {
      return (() => {
        this.screenheight = (document.documentElement.clientHeight-65) + 'px';
        console.log(this.screenheight)
      })();
    };
  },
  methods: {
    toService() {
      document.getElementById("main_service").scrollIntoView({
        behavior: "smooth"
      });
    },
    goTo(url) {
      let query = url == "/case" ? { type: 1 } : null;
      this.$router.push({
        path: url,
        query: query
      });
    },
    //测试
    setActiveItem(index) {
      console.log(index, 232);
      this.bannerActive = index;
      if (index != this.bannerActive) this.$refs.carousel.setActiveItem(index);
    }
  }
};
</script>

<style lang="less" scoped>
@keyframes move {
  from {
    top: -1000px;
  }
  to {
    top: 0;
  }
}
@keyframes fadeInUp {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.home_title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.home_content {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-top: 10px;
}
.about_ying {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 82, 217, 0.4);
}
.about_title {
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #243578;
}
::v-deep .el-carousel__indicators--horizontal {
  display: none !important;
}

.home {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  .el-carousel__indicators {
    display: none;
  }
  .banner_0 {
    //width: 100%;
    //height: 100%;
    //background-size: cover;
    background: url("~@/assets/images/banner/home.png") no-repeat center;
    // background-size: 100% 100%;
  }
  .banner_1 {
    background: url("~@/assets/images/banner/home2.png") no-repeat center;
    background-size: 100% 100%;
  }
  .banner_2 {
    background: url("~@/assets/images/banner/home3.png") no-repeat center;
    background-size: 100% 100%;
  }
  .banner_3 {
    background: url("~@/assets/images/banner/home4.png") no-repeat center;
    background-size: 100% 100%;
  }
  .banner2_imgbox {
    margin-top: 40px;
    display: flex;
    .itemCalc{
      width: calc(100vw/20);
      height: calc(100vw/20);
    }
    .content {
      font-size: calc(100vw/45);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0165de;
    }
  }

  .banner {
    position: relative;
    padding-top: 36px;
    width: 100%;
    height: 100%;
    background-size: cover;
    .banner_c {
      width: 100%;
      .banner_box {
        margin-top: calc(100vh/5);
        margin-left: calc(100vw/6);
        .banner_title {
          font-size: calc(100vw/20);
          font-family: COCA-COLACAREFONT-TEXTBOLD--GB1-0,
          COCA-COLACAREFONT-TEXTBOLD--GB1;
          font-weight: normal;
          color: #ffffff;
          text-shadow: 2px 2px 8px #0057e4;
        }
        .banner_content {
          font-size: calc(100vw/35);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f5f5f5;
          margin-top: calc(100vh/20);
        }
        .word_btn {
          margin-top: calc(100vh/20);
          display: block;
          width: 267px;
          height: 80px;
          text-align: center;
          line-height: 80px;
          cursor: pointer;
          background: linear-gradient(44deg, #0264ff 0%, #0052d9 100%);
          box-shadow: 0px -11px 45px 0px rgba(0, 82, 217, 0.6);
          border-radius: 0px 13px 0px 13px;
          font-size: 28px;
          font-weight: bold;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    .banner_tip {
      display: flex;
      justify-content: center;
      position: absolute;
      /* margin-left: 600px; */
      z-index: 9999;
      left: 50%;
      bottom: 10%;
      .tip_item {
        cursor: pointer;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        border-radius: 4px;
        background: #ffffff;
      }
      .tip_active {
        width: 30px;
        background: #0052d9;
      }
    }
    .banner_footer {
      display: flex;
      width: 100%;
      background: rgba(214,232,255,0.7);
      position: absolute;
      padding: 0 200px;
      bottom: 0;
      .footer_item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 0;
        cursor: pointer;
        .item_icon {
          width: 50px;
          height: 50px;
          margin-right: 18px;
        }
        .item_word {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #243578;
          .word_title {
            font-size: 20px;
          }
          .word_c {
            font-size: 15px;
          }
        }
      }
      .footer_active {
        background: rgba(0,114,247,0.3);
      }
    }
  }
}
.main_box {
  // width: 1200px;
  margin: 20px auto;
  padding-top: 68px;
  .about_box {
    //   width: 100%;
    display: flex;
    justify-content: space-between;
    .about_left {
      width: 40%;
      padding-right: 48px;
    }
    .about_right {
      width: 60%;
      padding-top: 175px;
    }
  }
  .w_list {
    margin-top: 24px;
    .list_item {
      transition: all 0.5s;
      margin-top: 28px;
      &:hover {
        background: linear-gradient(
            180deg,
            rgba(245, 251, 255, 0.35) 0%,
            #e6f4ff 100%
        );
        border: 2px solid #e6f4ff;
        border-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0),
            rgba(98, 167, 255, 1)
        )
        2 2;
      }
      width: 300px;
      height: 230px;
      border: 2px solid transparent;
      text-align: center;
      padding: 30px 20px;
      .item_icon {
        height: 75px;
      }
      .item_title {
        margin: 18px 0 10px;
        width: 252px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 50px;
      }
      .item_c {
        color: #666666;
      }
    }
  }
  .case_box {
    padding-top: 100px;
    .case_list {
      display: flex;
      padding-top: 75px;
      .list_item {
        width: calc(100% / 3);
        flex-shrink: 0;
        padding: 5px 10px;
        cursor: pointer;
        background: #ffffff;
        margin-right: 42px;
        &:hover {
          .item_icon {
            transform: scale(1.1);
          }
          .item_title,
          .item_c {
            color: #0052d9;
          }
        }
        .item_mask {
          width: 100%;
          overflow: hidden;
          margin-bottom: 10px;
          border-radius: 15px;

          box-shadow: 2px 12px 30px 0px rgb(165 165 165 / 20%)
        }
        .item_icon {
          width: 100%;
          height:265px;
          transition: all 0.5s;
        }
        .item_title,
        .item_c {
          padding: 5px 24px;
        }
        .item_title {
          font-size: 30px;
          line-height: 32px;
        }
        .item_c {
          margin-top: 15px;
        }
      }
    }
    .case_more {
      margin-top: 90px;
      .more_btn {
        margin: 0 auto;
        text-align: center;
        width: 128px;
        height: 50px;
        line-height: 48px;
        border-radius: 4px;
        border: 1px solid #ffb825;
        background: #ffb825;
        font-size: 28px;
        font-weight: 400;
        color: #fff;
        position: relative;
        cursor: pointer;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 80px;
          height: 1px;
          background: #ffb825;
          top: 25px;
        }
        &::before {
          left: -81px;
        }
        &::after {
          right: -81px;
        }
      }
    }
  }
  .serve_box {
    .serve_list {
      margin-top: 65px;
      display: flex;
      justify-content: space-between;
      .list_item {
        margin-right: -74px;
        transition: all 0.5s;
        &:hover {
          box-shadow: 2px 12px 30px 0px rgba(0, 82, 217, 0.2);
          .item_icon {
            transform: scale(1.1);
          }
        }
        width: 21%;
        height: 536px;
        background: #ffffff;
        box-shadow: 2px 12px 30px 0px rgba(165, 165, 165, 0.2);
        border-radius: 4px;
        text-align: center;
        padding: 70px 48px 81px 48px;
        .item_mask {
          width: 96px;
          height: 96px;
          overflow: hidden;
          margin: 0 auto;
        }
        .item_icon {
          width: 100%;
          height: 100%;
          transition: all 0.5s;
        }
        .item_title {
          margin: 30px 0;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #031d5b;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -10px;
            margin-left: -40px;
            width: 80px;
            height: 5px;
            background: #0052d9;
          }
        }
        .item_c {
          font-weight: bold;
          color: #5b6c94;
        }
      }
    }
  }
}

.float {
  position: fixed;
  top: 45%;
  left: 45px;
  z-index: 99999;
  cursor: pointer;
  .f_item {
    position: relative;
    .item_show {
      width: 64px;
      height: 64px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 2px rgba(165, 165, 165, 0.2);
      text-align: center;
      padding-top: 8px;
      &:hover {
        box-shadow: 0px 2px 6px 2px rgba(0, 82, 217, 0.2);
      }
      .show_icon {
        height: 25px;
      }
      .show_word {
        margin-top: 6px;
        font-size: 12px;
        color: #000000;
      }
    }
    .item_hide {
      display: none;
      position: absolute;
      box-shadow: 0px 2px 6px 2px rgba(0, 82, 217, 0.2);
      width: 145px;
      top: 0;
      left: 70px;
    }
    .item_show:hover + .item_hide {
      display: block;
    }
  }
  .f1 {
    margin-bottom: 30px;
    .item_hide {
      height: 64px;
      background: #ffffff;
      font-size: 12px;
      padding: 15px 0 15px 12px;
      color: #000000;
      line-height: 17px;
    }
  }
}
</style>
