<template>
  <div class="question_box w1200">
    <div class="question_title">问题定义</div>
    <div class="question_content">
      <div class="question_left">
        <div>
          现在文件的递转由线下转为线上，通过指定下一办理人员及时将文件传递到下一节点，将会大大提高文件的周转效率，也节约了大量的纸质资源，实现了灵活化办公。
        </div>
        <div style="margin-top: 50px">
          <span class="yuan"></span>
          <span class="yuan_title">目前资产管理所面临的问题</span>
        </div>
        <div class="content" style="margin: 20px 0">
          传统的管理办法已经无法满足业务需求，请求建设信息化管理平台来适应与日俱增的业务量。
        </div>
      </div>
      <div class="question_right">
        <img
          style="height: 200px"
          :src="require('@/assets/images/province/right.png')"
          alt=""
        />
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
      <div
        class="content_box"
        :class="tab == item.id ? 'checkStyle' : 'outStyle'"
        v-for="item in contentList"
        :key="item.id"
        @click="cutTab(item)"
      >
        <div class="title">
          <div
            class="yuan"
            :style="{ background: tab == item.id ? '#fff' : '#333' }"
          ></div>
          <div
            class="yuan_title"
            style="font-size: 20px; display: inline-block; margin-bottom: 5px"
          >
            {{ item.title }}
          </div>
        </div>
        <div
          class="content"
          :style="[{ color: tab == item.id ? '#fff' : '#333' }]"
        >
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentList: [
        {
          id: 1,
          title: "资产混乱",
          content:
            "没人能准确掌握资产状况有多少？在哪里？谁用着？正常？已损坏？履历是否完整可查？闲置？重复购置？",
        },
        {
          id: 2,
          title: "账务过多",
          content:
            "实物监管困难，想管管不好；统计汇总困难，数据不准确；资产购建审批缺乏依据，利旧困难；内控与审查风险；实物资产管理的制度难以落到实处；资产清查工作组织困难,清查不准；",
        },
        {
          id: 3,
          title: "管理成本高",
          content:
            "传统方式管理固定资产（例如Excel）繁琐、容易出错，盘点工作量大，耗时长，导致人员工作不仔细，容易敷衍，且盘点结果无法追溯",
        },
        {
          id: 4,
          title: "账务不符",
          content:
            "资产调拨或移交常忽略手续，时间长了总弄不清手中的资产状况；每年的资产清查总是兴师动众，手中工作太多，总想应付了事；组件（如：板卡、模块）更换频繁，很难进行实物跟踪",
        },
      ],
      tab: 1,
    };
  },
  methods: {
    cutTab(val) {
      this.tab = val.id;
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.yuan {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #0052d9;
}
.yuan_title {
  font-size: 26px;
  font-weight: 500;
}
.content {
  font-size: 18px;
  color: #333333;
}

.question_box {
  margin-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .question_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .question_content {
    display: flex;
    justify-content: space-between;
    .question_left {
      width: 45%;
    }
    .question_right {
      width: 45%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.content_box {
  width: 45%;
  height: 171px;
  padding: 20px 50px 65px 30px;
  margin-bottom: 50px;
  //   background: #ffffff;
  //   box-shadow: 0px 0px 20px 0px #d6dde7;
  border-radius: 10px 10px 60px 10px;
  cursor: pointer;
}
.outStyle {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #d6dde7;
  color: #333 !important;
}
.checkStyle {
  background: #0052d9;
  color: #fff;
}
</style>
