<template>
  <div class="centerM">
    <div class="content_left">
      <div class="l_title">关于项目</div>
      <div v-for="item in deslist" :key="item.title" class="l_content">
        <p class="x_title">{{ item.title }}</p>
        <span class="x_des">{{ item.des }}</span>
      </div>
    </div>
    <div class="content_right">
      <img
        :src="require('@/assets/images/meteorological/aboutMessage.png')"
        alt="项目示例图"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      deslist: [
        {
          title: "项目效率",
          des: "是指能够对室外空气进行质量监测，对室内空气环境进行检测与改善治理，并且能够定期生成数据报表的软硬件组合的整体系统。 对于数据信息集中收集管理，形成了庞大的数据库，能够实现大数据分析。",
        },
        {
          title: "空气质量",
          des: "是依据空气中污染物浓度的高低来判断的。某个区域的空气质量达到几级标准主要是看这个地方空气中各种污染物（包括：烟尘、总悬浮颗粒物、可吸入颗粒物（PM10）、细颗粒物（PM2.5）、二氧化氮、二氧化硫、一氧化碳、臭氧、挥发性有机化合物等）达到几级标准。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.centerM {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // padding: 100px 200px;
  padding: 100px 0;
  .content_left {
    width: 50%;
    color: #333333;
    font-family: PingFangSC-Semibold, PingFang SC;
    .l_title {
      border-left: 4px solid #5DC42E ;
      padding-left: 4px;
      font-weight: 500;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .l_content {
      width: 80%;
      .x_title {
        font-size: 22px;
        font-weight: 600;
      }
      .x_des {
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  .content_right {
    width: 50%;
    height: 524px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(93, 196, 46, 0.4) 100%
    );
    box-shadow: 0px 0px 20px 0px #d6dde7;
    border-radius: 100px 0px 0px 100px;
    backdrop-filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
</style>