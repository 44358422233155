<template>
    <div class="trait">
        <div class="banner w_banner">
            <Header></Header>
            <div class="banner_title">{{ titleList[type] }}</div>
            <div class="banner_sub_title">{{ subTitleList }}</div>
        </div>
        <div class="main w1200">
            <div class="main_nav">
                <div 
                    v-for="(item, index) in titleList" 
                    :key="index" 
                    @click="type = index"
                    class="nav_item" 
                    :class="{ 'nav_active': index == type }"
                >
                    {{ item }}
                </div>
            </div>
            <div class="main_c">
                <div v-for="(item, index) in cList[type]" :key="index" class="c_item">
                    <div class="item_title">{{ item.title }}</div>
                    <div class="item_c">{{ item.content }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header'
export default {
    name: 'trait',
    components: {
        Header
    },
    data() {
        return {
            type: 0,
            titleList: ['小程序的特点','H5的特点','微信公众号的特点','微商城的特点'],
            subTitleList: '微信小程序开发+公众号+朋友圈、量身定制+独立部署',
            cList: [
                [
                    { 
                        title: '方便、快捷', 
                        content: '范围广，可以跨平台访问;方便而快，快速的访问带来更好的体验。' 
                    },
                    { 
                        title: '灵活性强', 
                        content: '由于是基于微信的产物，客户可以直接从微信聊天进入小程序，也可以在小程序随时切换回聊天，灵活快捷。' },
                    { 
                        title: '推广也方便', 
                        content: '小程序分享无比方便，可以在微信公众号、附近小程序、线下、app上都可以去推广，线上线下推广都方便。' },
                    { 
                        title: '客户功能促进客户感知', 
                        content: '商家可以利用客服功能在有需要的时候给客户推送消息，有优惠促销活动也可以通知客户，用户能够通过客服功能直接跟客户的微信对接，促进转化率，提高客户感知。' 
                    }
                ],
                [
                    { 
                        title: '页面简练、信息丰富', 
                        content: '利用H5的技术所完成的网站改良了网站页面多媒体元素的应用，将从前刻意削减的动画、视频、图片等元素完美应用于H5网站，并且不用担心网站的浏览不顺畅，这样的改良使得H5网站页面字少图多，看起来十分舒适，并且通过视频等方式可以在页面上放入更多的内容。' 
                    },
                    { 
                        title: '有利于网站的优化', 
                        content: '使用H5技术建立的网站代码编写不同于传统的网站代码，对比起来H5的代码要简单得多，也因为这样H5网站更容易被搜索引擎所发现，应用多媒体的环境中也更容易被抓取，这样就能够使网站的优化会更加的轻松。' 
                    },
                    { 
                        title: '传播效果强', 
                        content: 'h5页面最大的优势是传播能力极强，有非常好的传播效果，是企业商家做品牌曝光，产品宣传的利器。' 
                    }
                ],
                [
                    { 
                        title: '可移动性强', 
                        content: '操作上更占优势：利用手机的可移动性，一机在手易操作，不限时间，地点，方便快捷。手机是每个人的必备贴身物品，微信可随时随地与网站对接，与电脑同步，与客户沟通。利用微信的可传播性和特有功能，可在最短的时间，不同的地点工作，也可随时把自己一瞬间产生的意念，构思，想法传播给对方，即可抢占先机，也可扩大渠道，变被动为主动。' 
                    },
                    { 
                        title: '用户群体不限，关注率高，即发即到', 
                        content: '只要在微信中编辑好文章，随手就可以发布，同时可充分利用微信二维码，关注了解最新商机，也可发布自己的产品、商机和新闻资讯，同时微信公众号的好处还可以统计分析，互转互动，了解需求。' 
                    },
                    { 
                        title: '覆盖面广、传播速度快、可积累口碑', 
                        content: '每个人都有自己需求，也有自己的朋友圈，可在朋友圈发布自己的信息，扩大自己的影响力，积累口碑，让朋友的朋友，客户的客户去宣传，从而达好人人皆知的口碑；一旦有朋友有需求，即会主动找上门。' 
                    },
                    { 
                        title: '营销方式灵活，过程多元化', 
                        content: '相比传统营销方式，微信营销更加多元化，微信不仅支持文字，更支持图片，支持语音、还有视频，以及混合式编辑方式，尤其语音和视频更贴近面对面交流，拉进了距离，可即时回复，使营销变得更真实，更有趣，更有说服力。' 
                    }
                ],
                [
                    { 
                        title: '微商城完善的数据分析', 
                        content: '微商城能够实现对产品的数据分析 ，包括商品的转化 率，访问时间，销量等全面监。并且，微信还能够有多种促销方案，比如发红包，现金券，折扣券，砍价等。' 
                    },
                    { 
                        title: '微商城能利用碎片化时间', 
                        content: '微商城不像PC商城，企业只需要推送相关活动优惠信息，微信就会用谈话的对话形式弹出，用户打开微信就能看到微商城的促销活动。快捷的打开入口，方便用户购买，用户无论是在任何时间，都能够随时随地的下单。' 
                    },
                    { 
                        title: '微商城能够与其他系统对接', 
                        content: '微商城的数据库能够与APP、PC电商平台等系统对接。实现同步化数据，并且微信还能够与企业内部系统对接。微商城全面实现线下门店管理，订单管理，货品管理，界面个性化，从而打造出适合商家风格的微商城。' 
                    },
                    { 
                        title: '微商城更宽广的营销入口', 
                        content: '微商城相比于其他的营销方式，更加的具有优势性。强大的商城系统，能够让客户帮商家将商品链接分享到微信朋友圈等渠道。商家推广效果付给用户佣金即可。并且可以通过会员制形式，让用户享受到更多的优惠，增加用户粘度。' 
                    }
                ]
            ]
        }
    },
    created() {
        this.type = this.$route.query.type
    }
}
</script>

<style lang="less">
    .trait {
        .banner {
            background: url(~@/assets/images/banner/applets.png) no-repeat center;
            font-family: PingFangSC-Regular, PingFang SC;
            text-align: center;
        }
        .main {
            display: flex;
            padding: 100px 0;
            .main_nav {
                flex: 1;
                padding: 15px 20px;
                border-left: 1px solid rgba(0,0,0,0.09);
                .nav_item {
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #7A7A7A;
                    cursor: pointer;
                }
                .nav_active {
                    color: #0052D9;
                    padding-left: 15px;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        border: 2px solid #0052D9;
                        top: 7px;
                        left: -26px;
                        background: #ffffff;
                    }
                }
                .nav_item:not(:first-child) {
                    margin-top: 10px;
                }
            }
            .main_c {
                width: 900px;
                padding: 15px 0 0 15px;
                .c_item {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    color: #333333;
                    line-height: 25px;
                    &:not(:first-child) {
                        margin-top: 30px;
                    }
                }
                .item_title {
                    font-weight: bold;
                }
                .item_c {
                    margin-top: 6px;
                    font-weight: 400;
                }
            }
        }
    }
</style>