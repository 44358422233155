<template>
    <div class="background">
        <div class="banner w_banner">
            <Header :menuActive = "2"></Header>
            <div class="banner_title">后台管理系统开发</div>
            <div class="banner_sub_title">
                快速创建Web应用程序<br>
                提供企业管理OA、ERP、CRM系统软件开发服务实现统一后台管理，多端同步操作使用
            </div>
        </div>
        <div class="main">
            <div class="main_system">
                <div class="w_main_title">系统平台搭建</div>
                <div class="w_main_sub_title w1200">无论是使用新兴技术、或利用定制开发能力或采用市场领先企业提供的成熟套装软件，我们的系统开发服务团队都能基于规范的方法和流程，将新的解决方案快速扩展至现有系统和业务流程中，为客户提供安全、稳定、高效的全方位系统服务，帮助企业加快信息化步伐，实现创新变革。</div>
                <div class="w_list">
                  <div v-for="item in systemList" :key="item.title" class="list_item">
                    <div class="item_mask">
                      <img :src="item.icon" class="item_icon">
                    </div>
                      <div class="item_title">{{ item.title }}</div>
                      <div class="item_c">{{ item.content }}</div>
                  </div>
                </div>
            </div>
            <div class="main_advant">
                <div class="advant_bg">
                    <div class="w_main_title">我们的优势</div>
                </div>
                <div class="advant_suspend">
                    <img src="@/assets/images/background/icon8.png" class="suspend_icon">
                    <div class="suspend_info">
                        <div class="info_title">优质品质快速，超高性价比</div>
                        <div class="w_list">
                            <div v-for="(item, index) in infoList" :key="index" class="list_item">{{ item }}</div>
                        </div>
                        <div @click="goTo" class="info_btn">立即定制</div>
                    </div>
                </div>
            </div>
            <div class="main_process">
                <process></process>
            </div>
            <div class="main_partner">
                <partner></partner>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import partner from '@/components/partner'
import process from '@/components/process'
export default {
    name: 'background',
    components: {
        Header,
        Footer,
        partner,
        process
    },
    data() {
        return {
            systemList: [
                {
                    icon: require('@/assets/images/background/icon1.png'),
                    title: '直销系统开发',
                    content: '针对不同直销行业，提供更多智能功能的微信基础服务系统平台。同时可以根据您的需求进行高级功能定制与开发。'
                },{
                    icon: require('@/assets/images/background/icon2.png'),
                    title: '分销系统开发',
                    content: '系统基于云端架构，由平台统一进行商品的上下架、配送及发货操作，完美打造出以分销商为中心的全新微信分销模式。'
                },{
                    icon: require('@/assets/images/background/icon3.png'),
                    title: '互助平台',
                    content: '帮助客户开发安全公开透明的互助平台，互助重大病情、意外等风险事故'
                },{
                    icon: require('@/assets/images/background/icon4.png'),
                    title: 'O2O生活通',
                    content: '生活通O2O系统功能模块包含商家管理、商品管理、促销管理、订单管理、会员管理、即使通讯、代理商等功能，好用、实用易用'
                },{
                    icon: require('@/assets/images/background/icon5.png'),
                    title: 'HIS(医疗)系统',
                    content: 'HIS系统、采用SaaS模式，有效解决诊所医疗信息化管理的各项问题。预约、登记、分诊、接诊、电子病历、检验检查、药品进销存、患者管理与便捷沟通等功能。'
                },{
                    icon: require('@/assets/images/background/icon6.png'),
                    title: '政务协作平台',
                    content: '系统安全高效，易维护易运营，高级别网络防护，数据加密存储。致力于为机关单位搭建一个安全、高效、稳定的网络服务平台。'
                }
            ],
            infoList: ['优质的服务','高品质定制','多行业案例','性价比更高','专业技术团队','快速的周期']
        }
    },
    methods: {
        goTo() {
            this.$router.push({
                path: '/customize'
            })
        }
    }
}
</script>

<style lang="less">
    .background {
        .banner {
            background: url(~@/assets/images/banner/b.png) no-repeat center;
            text-align: center;
        }
        .main {
            .main_system {
                padding-top: 100px;
                text-align: center;
                .w_list {
                    flex-wrap: wrap;
                    .list_item {
                        width: 330px;
                        margin-top: 75px;
                        padding-bottom: 20px;
                        border: 1px solid #0052D9;
                        //animation-duration: 2s;
                        transition: all .5s;
                        &:hover {
                            border: none;
                            box-shadow: 0px 6px 47px 0px rgba(0,82,217,0.2);
                          .item_icon{
                            transform: scale(1.1);
                          }
                        }
                      .item_mask{
                        width: 100%;
                        overflow: hidden;
                      }
                        .item_icon {
                            width: 100%;
                          transition: all .5s;
                        }
                        .item_title,.item_c {
                            font-weight: 400;
                            text-align: center;
                            padding: 0 8px;
                        }
                        .item_title {
                            margin: 10px 0 18px;
                        }
                    }
                }
            }
            .main_advant {
                width: 100%;
                margin-top: 100px;
                .advant_bg {
                    width: 100%;
                    height: 540px;
                    background: url('~@/assets/images/background/icon7.png') no-repeat center;
                    background-size: 100% 100%;
                    .w_main_title {
                        padding-top: 100px;
                        color: #FFFFFF;
                    }
                }
                .advant_suspend {
                    width: 1200px;
                    margin: 0 auto;
                    position: relative;
                    height: 400px;
                    box-shadow: 0px 2px 30px 0px rgba(0,82,217,0.3);
                    top: -300px;
                    display: flex;
                    .suspend_icon {
                        height: 100%;
                    }
                    .suspend_info {
                        padding-top: 60px;
                        flex: 1;
                        height: 100%;
                        background: #ffffff;
                        .info_title {
                            text-align: center;
                            font-size: 36px;
                            font-weight: 500;
                            color: #0052D9;
                        }
                        .w_list {
                            flex-wrap: wrap;
                            padding: 0 20px;
                            width: 100%;
                            .list_item {
                                width: 136px;
                                height: 45px;
                                text-align: center;
                                line-height: 45px;
                                margin-top: 36px;
                                border-radius: 25px;
                                border: 1px solid #0052D9;
                                font-size: 16px;
                                color: #0052D9;
                            }
                        }
                        .info_btn {
                            width: 200px;
                            height: 50px;
                            text-align: center;
                            line-height: 50px;
                            margin: 40px auto 0;
                            background: #0052D9;
                            border-radius: 4px;
                            position: relative;
                            color: #ffffff;
                            cursor: pointer;
                            &::after {
                                content: '';
                                position: absolute;
                                top: 20px;
                                width: 8px;
                                height: 8px;
                                margin-left: 8px;
                                border-top: 1px solid #ffffff;
                                border-right: 1px solid #ffffff;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
            .main_process {
                margin: -200px 0 100px;
            }
        }
    }
</style>