<template>
    <div>
        <banner></banner>
        <centerM></centerM>
        <problem></problem>
        <exhibition />
        <partner></partner>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '@/components/Footer'
import partner from '@/components/partner'
import banner from '@/views/case/caseDetail/components/banner'
import centerM from '@/views/case/caseDetail/components/centerM'
import problem from '@/views/case/caseDetail/components/problem'
import exhibition from '@/views/case/caseDetail/components/exhibition'

export default {
    name: "CZTStandard",
    components: {
        Footer,
        partner,
        banner,
        centerM,
        problem,
        exhibition
    },
}
</script>

<style scoped>

</style>