<template>
  <div class="vision_box">
    <div class="bg">
      <div class="yuan_box">
        <span class="yuan"></span>
        <span class="yuan_title">视觉规范</span>
      </div>
      <div class="content">
        <div class="icon_box">
          <div class="icon_title">
            <span class="left">功能</span>
            <span class="right">图标</span>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 85px">
            <img
              :src="require('@/assets/images/meteorological/iconbox.png')"
              alt=""
            />
          </div>
        </div>
        <div class="size_box">
          <div class="size_title">常用字体</div>
          <div class="size_top">
            <div style="font-size: 14px">
              <p>苹方-简</p>
              <p>14pt</p>
            </div>
            <div style="font-size: 16px; margin-top: -7px" class="16pt mar20">
              <p>苹方-简</p>
              <p>16pt</p>
            </div>
            <div style="font-size: 18px; margin-top: -16px" class="18pt mar20">
              <p>苹方-简</p>
              <p>18pt</p>
            </div>
            <div style="font-size: 20px; margin-top: -24px" class="20pt mar20">
              <p>苹方-简</p>
              <p>20pt</p>
            </div>
          </div>
          <div>
            <div class="size_title" style="margin-top: 10px">常用颜色</div>
            <ul>
              <li v-for="item in colorList" :key="item.id">
                <div class="color_box" :style="{ background: item.color }"></div>
                <div style="width: 100%;display:flex;">
                  <span style="margin: 0px 20px 0px 16px">{{ item.color }}</span>
                  <span>{{ item.content }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      colorList: [
        {
          id: 1,
          color: "#5DC42E",
          content: "主色调，用于按钮，图标，提示文字以及选中状态颜色",
        },
        {
          id: 2,
          color: "#20A6FE",
          content: "用于页面按钮，折线图线条",
        },
        {
          id: 3,
          color: "#FD4D4D",
          content: "用于页面删除按钮",
        },
        {
          id: 4,
          color: "#333333",
          content: "用于标题，内容",
        },
        {
          id: 5,
          color: "#999999",
          content: "用于未输入内容、简介等",
        },
      ],
    };
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content_box {
  width: 45%;
  height: 464px;
  margin-top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #d6dde7;
}
.mar20 {
  margin-left: 40px;
}
.vision_box {
  height: 690px;
  background: rgba(93, 196, 46, 0.2);
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  padding: 40px 0 109px 0;
  .bg {
    width: 1200px;
    margin: 0 auto;
  }
  .yuan_box {
    .yuan {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #5dc42e;
    }
    .yuan_title {
      font-size: 26px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .icon_box {
      .content_box;
      .icon_title {
        display: flex;
        justify-content: center;
        margin-top: 43px;
        .left {
          font-size: 72px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 100px;
          letter-spacing: 1px;
          -webkit-text-stroke: 1px #5dc42e;
          text-stroke: 1px #5dc42e;
        }
        .right {
          font-size: 72px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #5dc42e;
          line-height: 100px;
          letter-spacing: 1px;
          margin-left: 5px;
        }
      }
    }
    .size_box {
      padding: 14px 36px 30px 36px;
      .content_box;
      .size_title {
        border-left: 2px solid #5dc42e;
        font-size: 20px;
        font-weight: 500;
        color: #343449;
        line-height: 20px;
        padding-left: 5px;
      }
      .size_top {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
      }
      .color_box {
        width: 60px;
        height: 24px;
        border-radius: 4px;
      }
    }
  }
}
li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
ul {
  padding: 0;
  font-size: 18px;
  font-weight: 400;
}
</style>
