<template>
    <div class="customize">
        <div class="banner w_banner">
            <Header></Header>
            <div class="banner_form">
                <div class="form_title">为您专属定制</div>
                <div class="form_sub_title">联系泽尚，获取项目报价</div>
                <div class="form_c">
                    <div class="c_left">
                        <div class="left_item">
                            <div class="item_label">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</div>
                            <el-input v-model="name" placeholder="您的姓名（必填）" class="item_input" />
                            <img src="@/assets/images/customize/icon1.png" class="item_icon">
                        </div>
                        <div class="left_item">
                            <div class="item_label">电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话</div>
                            <el-input v-model="mobile" placeholder="您的电话（必填）" class="item_input" />
                            <img src="@/assets/images/customize/icon2.png" class="item_icon">
                        </div>
                        <div class="left_item">
                            <div class="item_label">微&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信</div>
                            <el-input v-model="wx" placeholder="您的微信" class="item_input" />
                            <img src="@/assets/images/customize/icon3.png" class="item_icon">
                        </div>
                        <div class="left_item">
                            <div class="item_label">项目需求</div>
                            <el-input v-model="req" placeholder="您的项目需求" class="item_input" />
                            <img src="@/assets/images/customize/icon4.png" class="item_icon">
                        </div>
                        <div class="left_btn">提交需求</div>
                    </div>
                    <div class="c_right">
                        <div class="right_item">
                            <img src="@/assets/images/customize/icon5.png" class="item_icon">
                            <div class="item_word">7*24服务热线：12312341234</div>
                        </div>
                        <div class="right_item">
                            <img src="@/assets/images/customize/icon6.png" class="item_icon">
                            <div class="item_word">郑州总部：郑州市高新技术开发区河南省国家科技园东区8号楼（腾讯众创空间）5层</div>
                        </div>
                        <img src="@/assets/images/icon/icon_qr.png" class="right_wx">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header'
export default {
    name: 'customize',
    components: {
        Header
    },
    data() {
        return {
            name: '',
            mobile: '',
            wx: '',
            req: ''
        }
    }
}
</script>

<style lang="less">
    .customize {
        .banner {
            height: 1080px;
            background: url(~@/assets/images/banner/customize.png) no-repeat center;
            .banner_form {
                width: 1065px;
                height: 690px;
                background: rgba(255,255,255,0.53);
                backdrop-filter: blur(7px);
                margin: 215px auto 0;
                padding: 75px 100px;
                .form_title {
                    font-size: 40px;
                    font-weight: bold;
                    color: #0052D9;
                    line-height: 54px;
                }
                .form_sub_title {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #ACB2BC;
                    line-height: 28px;
                }
                .form_c {
                    padding-top: 50px;
                    display: flex;
                    justify-content: space-between;
                    .c_left {
                        .left_item {
                            display: flex;
                            align-items: center;
                            margin-bottom: 30px;
                            position: relative;
                            &:nth-of-type(4) {
                                .item_icon {
                                    left: 126px
                                }
                            }
                            .item_label {
                                width: 80px;
                                margin-right: 30px;
                                color: #0052D9;
                            }
                            .item_input {
                                width: 300px;
                                height: 56px;
                                background: #FFFFFF;
                                border-radius: 8px;
                                backdrop-filter: blur(10px);
                                input {
                                    height: 56px;
                                    line-height: 56px;
                                    padding-left: 55px;
                                }
                            }
                            .item_icon {
                                height: 26px;
                                position: absolute;
                                left: 122px;
                            }
                        }
                        .left_btn {
                            width: 180px;
                            height: 56px;
                            text-align: center;
                            line-height: 56px;
                            cursor: pointer;
                            position: relative;
                            left: 230px;
                            background: #0052D9;
                            border-radius: 8px;
                            backdrop-filter: blur(10px);
                            color: #FFFFFF;
                            font-size: 24px;
                        }
                    }
                    .c_right {
                        flex: 1;
                        padding-left: 100px;
                        text-align: right;
                        .right_item {
                            display: flex;
                            margin-bottom: 45px;
                            &:first-of-type {
                                align-items: center;
                            }
                            .item_icon {
                                width: 60px;
                                height: 60px;
                                flex-shrink: 0;
                                margin-right: 20px;
                            }
                            .item_word {
                                font-size: 20px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                                line-height: 28px;
                                text-align: justify;
                            }
                        }
                        .right_wx {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
</style>