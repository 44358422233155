<template>
  <div class="banner">
    <Header :menuActive="1"></Header>
    <div class="content w1200">
      <div class="c-left">
        <p class="c_title">项目详情页</p>
        <h3>省级政府公物仓管理平台</h3>
        <div class="c_line"></div>
        <p class="c_point">实现灵活化办公</p>
        <span
          >提高省资产管理中心办理业务的效率<br />
          方便有效的辅助各项业务的有效开展</span
        >
      </div>
      <div class="c_right">
        <img
          :src="require('@/assets/images/province/rightbanner.png')"
          alt="项目示例图"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "banner",
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 620px;
  background-image: url("../../../../assets/images/province/banner.png");
  padding-top: 36px;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    .c-left {
      color: #ffffff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-top: -20px;
      .c_title {
        font-size: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
        margin-bottom: 60px;
      }
      h3 {
        margin: 0;
        width: 648px;
        height: 60px;
        font-size: 50px;
        line-height: 40px;
        // border-bottom: 1px solid #0052D9;
        padding-bottom: 20px;
        margin-bottom: 17px;
      }
      .c_line {
        width: 522px;
        height: 1px;
        border: 1px solid #0052d9;
        margin-bottom: 17px;
      }
      .c_point {
        margin: 0;
        font-size: 28px;
        color: #0052d9;
        margin-bottom: 13px;
      }
      span {
        font-size: 24px;
      }
    }
    .c_right {
      img {
        width: 565px;
        height: 400px;
      }
    }
  }
}
</style>