<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style lang="less">
    *{
      box-sizing: border-box;
    }
    html,body{
        width: 100%;
        min-width: 1260px;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        font-size: 16px;
        color: #333;
        font-family: PingFangSC-Medium, PingFang SC;
    }
    .w1200 {
        width: 1200px;
        margin: 0 auto;
    }
    .w_banner {
        width: 100%;
        height: 620px;
        padding-top: 36px;
        background-size: 100% 100%!important;
        .banner_title {
            padding-top: 100px;
            font-size: 64px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 90px;
        }
        .banner_sub_title {
            padding-top: 15px;
            font-size: 30px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 42px;
        }
        .banner_tip {
            padding-right:500px;
            margin-top: 12px;
            font-size: 28px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 40px;
        }
    }
  .w_main_title {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      color: #333333;
      line-height: 56px;
  }
  .w_main_sub_title {
      text-align: center;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      margin-top: 10px;
  }
  .w_list {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .list_item {
          color: #333333;
          flex-shrink: 0;
          .item_title {
              font-size: 20px;
              font-weight: bold;
          }
          .item_c {
              font-size: 16px;
              font-weight: 400;
          }
      }
  }
  li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
ul {
  padding: 0;
  font-size: 18px;
  font-weight: 400;
}
.footer_button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
