import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import moment from 'moment'
import router from './router'
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.prototype.$moment = moment
Vue.config.productionTip = false
document.title = '泽尚网络'
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
router.afterEach(() => {
  window.scrollTo(0, 0)
})
