<template>
    <div class="w_process">
        <div class="w_main_title">开发流程</div>
        <div class="process_list">
            <div v-for="(item, index) in processList" :key="index" class="list_item">
                <div class="item_cn">{{ item.cn }}</div>
                <div class="item_en">{{ item.en }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'process',
    props: {
    },
    data() {
        return {
            processList: [
                { cn: '前期沟通', en: 'Communicate' },
                { cn: '项目评估', en: 'Assessment' },
                { cn: '产品规划', en: 'Programming' },
                { cn: '程序实现', en: 'Realization' },
                { cn: '质量检测', en: 'Testing' },
                { cn: '部署上线', en: 'Go online' }
            ]
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style lang="less">
    .w_process {
        .process_list {
            width: 1200px;
            margin: 60px auto 0;
            display: flex;
            justify-content: space-between;
            .list_item {
                width: 180px;
                height: 180px;
                background: url('~@/assets/images/background/icon9.png') no-repeat center;
                background-size: 100% 100%;
                text-align: center; 
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                &:hover {
                    cursor: pointer;
                    color: #0052D9;
                }
                .item_cn {
                    font-size: 20px;
                    margin: 50px 0 30px;
                }
                .item_en {
                    font-size: 16px
                }
            }
        }
    }
</style>