<template>
    <div class="wapp">
        <div class="banner w_banner">
            <Header :menuActive = "5"></Header>
            <div class="banner_title w1200">移动APP定制开发</div>
            <div class="banner_sub_title w1200">量身打造专业定制</div>
            <div class="banner_tip w1200">拒绝项目延期！费用过高！开发烂尾 ！助力企业抢占互联网市场！</div>
        </div>
        <div class="main">
            <div class="main_puzzled">
                <div class="w_main_title">你是否有这些困惑？</div>
                <div class="w_main_sub_title">我们为您提供一站式解决方案，助您APP应用开发无忧！</div>
                <div class="w_list">
                    <div v-for="item in puList" :key="item.title" class="list_item">
                        <img :src="item.icon" class="item_icon">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_c">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="main_serve">
                <div class="w_main_title">我们能为你提供的服务</div>
                <div class="w_main_sub_title">我们专注打造高质量用户体验，开发有独特卖点的APP，让你一劳永逸！</div>
                <div class="w_list">
                    <div v-for="item in serveList" :key="item.title" class="list_item">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_c">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="main_advantage">
                <div class="w_main_title">服务优势</div>
                <div class="w_main_sub_title">多年行业经验、资深技术开发团队、企业首选、用户增幅30%</div>
                <div class="w_list">
                    <div v-for="item in adList" :key="item.title" class="list_item">
                        <div class="item_title">{{ item.title }}</div>
                        <div class="item_c">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="main_industry">
                <div class="w_main_title">我们可提供服务的行业</div>
                <div class="w_main_sub_title">多元化、多样化、量身定制满足各行业需求</div>
                <div class="w_list">
                    <div @mouseover="inActive = index" v-for="(item, index) in indList" :key="item.title" class="list_item" :class="{ list_active: inActive == index }">
                        <img v-if="inActive != index" :src="item.icon" class="item_icon">
                        <img v-else :src="item.icon2" class="item_icon">
                        <div class="item_c">{{ item.title }}</div>
                    </div>
                </div>
                <img :src="require('@/assets/images/app/icon'+ (Number(inActive) + 5) +'_s.png')" class="in_img">
            </div>
            <div class="main_process">
                <process></process>
            </div>
            <div class="main_partner">
                <partner></partner>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import partner from '@/components/partner'
import process from '@/components/process'
export default {
    name: 'app',
    components: {
        Header,
        Footer,
        partner,
        process
    },
    data() {
        return {
            puList: [
                {
                    icon: require('@/assets/images/app/icon1.png'),
                    title: '不懂技术',
                    content: '搭建团队成本太高，不懂管理风险太大',
                },{
                    icon: require('@/assets/images/app/icon2.png'),
                    title: '选择困难',
                    content: '想找一家技术实力雄厚的APP开发公司',
                },{
                    icon: require('@/assets/images/app/icon3.png'),
                    title: '定位不清',
                    content: '有一个绝佳的想法，不知道如何实现',
                },{
                    icon: require('@/assets/images/app/icon4.png'),
                    title: '价浮动大',
                    content: '资讯多家公司，报价不明确差距大',
                }
            ],
            serveList: [
                { title: '没有开发团队？', content: '我们公司确保每位员工都是业内资深人士' },
                { title: '想和懂技术的人沟通？', content: '技术总监一对一技术交流全程跟踪' },
                { title: '不想养团队？', content: '全程开发透明实施项目进度汇报' },
                { title: '该策划哪些功能？', content: '多年行业经验精准定位需求清单' }
            ],
            adList: [
                { title: '一对一专属服务', content: '资深产品经理一对一服务，免费制作开发方案' },
                { title: '个性定制', content: '从用户实际需求出发，个性定制，提升用户体验' },
                { title: '高性价比', content: '成熟技术团队，一站式服务，降低企业开发成本' },
                { title: '卓越品质', content: '100%原生开发，后期刻碟待开发，灵活性强' },
                { title: '优质服务', content: '专业售后团队，为您的软件保驾护航' }
            ],
            indList: [
                {
                    icon: require('@/assets/images/app/icon5.png'),
                    icon2: require('@/assets/images/app/icon5_w.png'),
                    title: '旅游类'
                },{
                    icon: require('@/assets/images/app/icon6.png'),
                    icon2: require('@/assets/images/app/icon6_w.png'),
                    title: '交通运输类'
                },{
                    icon: require('@/assets/images/app/icon7.png'),
                    icon2: require('@/assets/images/app/icon7_w.png'),
                    title: '电商类'
                },{
                    icon: require('@/assets/images/app/icon8.png'),
                    icon2: require('@/assets/images/app/icon8_w.png'),
                    title: '社交类'
                },{
                    icon: require('@/assets/images/app/icon9.png'),
                    icon2: require('@/assets/images/app/icon9_w.png'),
                    title: '政府门户类'
                },{
                    icon: require('@/assets/images/app/icon10.png'),
                    icon2: require('@/assets/images/app/icon10_w.png'),
                    title: '房地产类'
                },{
                    icon: require('@/assets/images/app/icon11.png'),
                    icon2: require('@/assets/images/app/icon11_w.png'),
                    title: '家政类'
                },{
                    icon: require('@/assets/images/app/icon12.png'),
                    icon2: require('@/assets/images/app/icon12_w.png'),
                    title: '餐饮类'
                }
            ],
            inActive: 0
        }
    }
}
</script>

<style lang="less">
    .wapp {
        .banner {
            background: url(~@/assets/images/banner/app.png) no-repeat center 100%;
            font-family: PingFangSC-Regular, PingFang SC;
            .banner_title {
                margin: 100px auto 32px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 520px;
                    height: 1px;
                    background: #ffffff;
                    left: 0;
                    bottom: -18px;
                }
            }
            .banner_sub_title {
                color: #0052D9;
            }
        }
        .main {
            .main_puzzled {
                margin-top: 100px;
                .w_list {
                    margin-top: 50px;
                    .list_item {
                        width: 25%;
                        text-align: center;
                        .item_icon {
                            width: 100px;
                        }
                        .item_title {
                            margin: 10px 0 30px;
                        }
                    }
                }
            }
            .main_serve {
                margin-top: 100px;
                .w_list {
                    margin-top: 50px;
                    .list_item {
                        width: 251px;
                        height: 141px;
                        padding: 24px 10px 0;
                        text-align: center;
                        box-shadow: 0px 2px 10px 0px rgba(165,165,165,0.3);
                        .item_c {
                            margin-top: 10px;
                        }
                    }
                }
            }
            .main_advantage {
                margin-top: 100px;
                .w_list {
                    margin-top: 50px;
                    .list_item {
                        width: 180px;
                        height: 165px;
                        padding: 40px 10px 0;
                        text-align: center;
                        border: 2px solid;
                        border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5), #dbdbdb) 1 1;
                      transition: all .5s;
                        &:hover {
                            border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(0, 82, 217, 1)) 1 1;
                        }
                        .item_c {
                            margin-top: 10px;
                        }
                    }
                }
            }
            .main_industry {
                margin-top: 100px;
                text-align: center;
                .w_list {
                    margin-top: 50px;
                    .list_item {
                        width: 100px;
                        height: 120px;
                        padding: 20px 10px 0;
                        text-align: center;
                        background: #F5F5F5;
                        border-radius: 2px;
                        .item_icon {
                            height: 36px;
                        }
                        .item_c {
                            margin-top: 20px;
                        }
                    }
                    .list_active {
                        background: #0052D9;
                        .item_c {
                            color: #ffffff;
                        }
                    }
                }
                .in_img {
                    width: 1100px;
                    margin-top: 100px;
                    transition: all .5s;
                }
            }
            .main_process {
                margin: 100px 0;
            }
        }
    }
</style>