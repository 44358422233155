<template>
  <div class="exhibition_box w1200">
    <div class="exhibition_title">项目展示</div>
    <img
      style="width: 100%"
      :src="require('@/assets/images/wisdom/abouct.png')"
      alt=""
    />
    <div class="footer_button">
      <el-button @click="toHome">返回首页</el-button>
      <el-button style="margin-left: 30px" @click="checkMore"
        >查看更多</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    checkMore() {
      this.$router.push("/case");
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.exhibition_box {
  margin-top: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .exhibition_title {
    font-size: 32px;
    font-weight: 500;
    border-left: 4px solid #388cff;
    padding-left: 5px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .footer_button {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }
}
</style>
