<template>
    <footer class="w_footer">
        <div class="footer_c">
            <div class="footer_column">
                <div class="column_title">产品服务</div>
                <div v-for="(item, index) in serviceList" :key="index" class="column_item" @click="goTo(item.path)">{{ item.name }}</div>
            </div>
            <div class="footer_column">
                <div class="column_title">关于泽尚</div>
                <div class="column_item column_contact">联系我们</div>
                <img src="@/assets/images/icon/icon_qr.png" class="wx">
                <div @click="goTo('/customize')" class="column_item pointer">立即定制</div>
            </div>
            <div class="footer_column footer_contact">
                <div class="column_title">联系我们</div>
                <div v-for="item in contactList" :key="item.title" class="column_item">
                    <img :src="item.icon" class="item_icon">
                    <div v-if="item.title" class="item_title">{{ item.title }}：</div>
                    <div class="item_c">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <div class="footer_b">
            <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023009255号-1</a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    props: {
    },
    data() {
        return {
            serviceList: [
                {
                    name:'移动开发',
                    path:'/app'
                },
                {
                    name: '小程序开发',
                    path:'/applets'
                },
                {
                name: '后台开发',
                    path:'/background'
                },
                {
                name: '区块链开发',
                    path: '/blockchain'
                }
            ],
            contactList: [
                {
                    icon: require('@/assets/images/icon/dianhua.png'),
                    title: '手机号',
                    content: '13521141981'
                }, {
                    icon: require('@/assets/images/icon/icon_location.png'),
                    title: '',
                    content: '河南省郑州市高新技术产业开发区8号楼5层501室'
                }
            ]
        }
    },
    created() {
    },
    methods: {
        goTo(url) {
            this.$router.push({
                path: url
            })
        }
    }
}
</script>

<style lang="less">
    .w_footer {
        padding: 55px 0 10px 0;
        background: #00348A;
        .footer_c {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .footer_column {
                flex-shrink: 0;
                color: #ffffff;
                position: relative;
                .column_contact:hover + .wx {
                    display: block;
                }
                .wx {
                    display: none;
                    position: absolute;
                    width: 114px;
                    left: 85px;
                    top: 57px;
                }
                .column_title {
                    font-size: 24px;
                    line-height: 33px;
                    margin-bottom: 20px;
                }
                .column_item {
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    .item_title, .item_c {
                        font-size: 14px;
                    }
                    .item_icon {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
                .pointer {
                    cursor: pointer;
                }
            }
            .footer_contact {
                .icon {
                    width: 24px;
                }
            }
        }
        .footer_b {
            text-align: center;
            line-height: 40px;
            a {
                color: #ffffff;
                text-decoration: none;
            }
            font-size: 12px;
        }
    }
</style>
