<template>
  <div>
    <banner />
    <exhibition />
    <partner></partner>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import partner from "@/components/partner";
import banner from "@/views/case/wisdom/components/banner";
import exhibition from "@/views/case/wisdom/components/exhibition";


export default {
  components: {
    Footer,
    partner,
    banner,
    exhibition
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style scoped>
</style>
