<template>
  <div class="problem_box w1200">
    <div class="title">问题定义</div>
    <div class="introduce">
      现在文件的递转由线下转为线上，通过指定下一办理人员及时将文件传递到下一节点，将会大大提高文件的周转效率，也节约了大量的纸质资源，实现了灵活化办公。
    </div>
    <div class="introduce_title">
      <span class="left"></span>
      <span class="right">目前资产管理所面临的问题</span>
      <div class="message">
        传统的管理办法已经无法满足业务需求，请求建设信息化管理平台来适应与日俱增的业务量。
      </div>
      <ul>
        <li v-for="item in list" :key="item.tab">
          <div
            class="list_box"
            @click="checkout(item.tab)"
            :style="
              checkTab == item.tab ? 'background:#0052D9' : 'background: #fff'
            "
          >
            <div style="display: flex">
              <div
                class="yuan"
                :style="
                  checkTab == item.tab ? 'background:#fff' : 'background: #333'
                "
              ></div>
              <div
                class="list_title"
                :style="checkTab == item.tab ? 'color:#fff' : 'color: #333'"
              >
                {{ item.title }}
              </div>
            </div>
            <div
              class="list_content"
              :style="checkTab == item.tab ? 'color:#fff' : 'color: #333'"
            >
              {{ item.content }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: "资产混乱",
          content:
            "没人能准确掌握资产状况有多少？在哪里？谁用着？正常？已损坏？履历是否完整可查？闲置？重复购置？",
          tab: 1,
        },
        {
          title: "账务过多",
          content:
            "实物监管困难，想管管不好；统计汇总困难，数据不准确；资产购建审批缺乏依据，利旧困难；内控与审查风险；实物资产管理的制度难以落到实处；资产清查工作组织困难,清查不准；",
          tab: 2,
        },
        {
          title: "管理成本高",
          content:
            "传统方式管理固定资产（例如Excel）繁琐、容易出错，盘点工作量大，耗时长，导致人员工作不仔细，容易敷衍，且盘点结果无法追溯",
          tab: 3,
        },
        {
          title: "账务不符",
          content:
            "资产调拨或移交常忽略手续，时间长了总弄不清手中的资产状况；每年的资产清查总是兴师动众，手中工作太多，总想应付了事；组件（如：板卡、模块）更换频繁，很难进行实物跟踪；",
          tab: 4,
        },
      ],
      checkTab: 0,
    };
  },
  methods: {
    checkout(val) {
      this.checkTab = val;
    },
  },
  watch: {},
  cteated() {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.problem_box {
  padding-top: 60px;
  font-family: PingFangSC-Regular, PingFang SC;
  .title {
    border-left: 4px solid #388cff;
    padding-left: 4px;
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .introduce {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 75%;

    background: #FFFFFF;
  }
  .introduce_title {
    margin-top: 50px;
    .left {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      background: #0052d9;
    }
    .right {
      font-size: 26px;
      font-weight: 500;
      margin-left: 10px;
      color: #333333;
    }
    .message {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
    .list_box {
      width: 100%;
      height: 171px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px #d6dde7;
      border-radius: 10px 10px 60px 10px;
      padding: 20px 60px 40px 20px;
      cursor: pointer;
      .yuan {
        width: 18px;
        height: 18px;
        border-radius: 10px;
        background: #333;
        margin-top: 5px;
      }
      .list_title {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin-left: 10px;
      }
      .list_content {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-top: 10px;
      }
    }
  }
}
ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
li {
  list-style: none;
  width: 45%;
  margin-top: 50px;
}
</style>
